import { TreeNode } from '../route-builder';

export const RestructuringModule = {
  segment: 'restructuring',
  children: {
    RestructuringComponent: { segment: '' },
    RestructuringIntroductionComponent: { segment: 'introduction' },
    RestructuringRequestListComponent: { segment: 'requests', params: ['filter', 'tags'] },
    RestructuringReportingListComponent: { segment: 'reporting', params: ['filter', 'tags'] },
    RestructuringCommitmentListComponent: { segment: 'commitment', params: ['filter', 'tags'] },
    RestructuringConditionLibraryClausesComponent: { segment: 'clauses', params: ['filters'] },
    RestructuringConditionLibraryTemplatesComponent: { segment: 'templates', params: ['filters', 'module'] }
  }
} as const satisfies TreeNode;
