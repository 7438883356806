import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EmbeddedViewRef,
  forwardRef,
  HostBinding,
  inject,
  Inject,
  Injector,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInput, MatLabel } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { COIN_FORMATS, DefaultControlValueAccessor } from '@coin/shared/util-helpers';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment, MomentInput } from 'moment';
import { timer } from 'rxjs';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { InputSize, MatFormFieldClass } from '../../input.types';
import { V2ButtonComponent } from '../button/v2-button.component';

@Component({
  selector: 'coin-v2-date-input',
  templateUrl: './v2-date-input.component.html',
  styleUrls: ['./v2-date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatDatepickerModule, MatIconModule, MatInput, FormsModule, MatTooltip, V2ButtonComponent, TranslateModule, MatFormField, MatLabel, MatSuffix],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: (injector: Injector) => {
        const inheritedFormats = injector.get(MAT_DATE_FORMATS, null, { skipSelf: true, optional: true });
        return { ...COIN_FORMATS, display: inheritedFormats?.display ?? COIN_FORMATS.display };
      },
      deps: [Injector]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useFactory: () => inject(LOCALE_ID).toString() },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2DateInputComponent),
      multi: true
    }
  ]
})
export class V2DateInputComponent extends DefaultControlValueAccessor<Moment> implements OnInit, OnDestroy {
  @ViewChild('todayButton') todayButton: TemplateRef<unknown>;

  @Input() label: string;
  @Input() placeholder: string;
  @Input() matFormFieldClass: MatFormFieldClass = 'customer-grey';
  @Input() boldValue = false;
  @Input() size: InputSize;
  @Input() min: Moment;
  @Input() max: Moment;
  @Input() disableDays?: number[];

  /**
   * * `input`: (default) the form value will update every time the input is updated
   * * `blur` the form value will only update after the input loses focus */
  @Input() updateOn: 'input' | 'blur' = 'input';

  private todayButtonView: EmbeddedViewRef<unknown>;

  @HostBinding('class')
  private get class(): string {
    return `size-${this.size}`;
  }

  constructor(
    private vcr: ViewContainerRef,
    private destroyRef: DestroyRef,
    @Inject(LOCALE_ID) public locale: string
  ) {
    super();
  }

  writeValue(value: MomentInput) {
    if (this.isInvalidDate(value)) {
      super.writeValue(null);
    } else {
      super.writeValue(moment(value ?? null).utc());
    }
  }

  protected insertTodayButton(): void {
    if (!this.todayButtonView) {
      this.todayButtonView = this.vcr.createEmbeddedView(this.todayButton);
      this.todayButtonView.rootNodes.forEach((node: HTMLElement) => node.remove());
    }

    timer(0)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const host = document.querySelector('.mat-calendar-spacer');
        this.todayButtonView.rootNodes.forEach(node => host?.appendChild(node));
      });
  }

  ngOnDestroy(): void {
    this.todayButtonView?.destroy();
  }

  protected selectToday(datePicker: MatDatepicker<unknown>) {
    const today = moment().utc().startOf('day');
    this.value = today;
    datePicker.close();
  }

  dayFilter = (d: Date): boolean => {
    if (this.disableDays && d) {
      const day = new Date(d).getDay();
      return !this.disableDays.includes(day);
    }
    return true;
  };

  protected onInputBlur(value: string) {
    this.onTouch(this.value);
    if (this.updateOn === 'blur' && !this.isSame(value)) {
      this.value = moment(value);
    }
  }

  protected onInputChange(value: string) {
    if (this.updateOn === 'input' && !this.isSame(value)) {
      this.value = moment(value);
    }
  }

  public isSame(value: MomentInput): boolean {
    if (!value && !this.value) return true;
    return !!this.value?.isSame(value, 'day');
  }

  private isInvalidDate(value: MomentInput): boolean {
    // BE sometimes populates empty date fields with this default date string, causing the dates to appear as though they have a valid value.
    if (moment(value).toISOString() === moment('0001-01-01T00:00:00.000Z').toISOString()) {
      console.warn('Invalid date string provided!');
      return true;
    }
    return false;
  }
}
