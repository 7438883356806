import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';

@Injectable({
  providedIn: 'root'
})
export class PaolaService {
  public openOrgCodeChecker(parameters: {
    action: 'edit' | 'create' | 'move';
    parentOrgCode: string;
    orgCode?: string;
    orgType?: string;
    orgClass?: string;
    orgUnit?: string;
    orgName?: string;
    neighbouringOrgCodes?: string[];
    targetParentOrgCode?: string;
    targetOrgCode?: string;
    targetOrgName?: string;
    targetNeighbouringOrgCodes?: string[];
  }): void {
    const url: URL = new URL(environment.paolaUrl);

    url.searchParams.append('a', parameters.action);
    url.searchParams.append('p_code', parameters.parentOrgCode);
    if (parameters.orgCode) url.searchParams.append('o_code', parameters.orgCode);
    if (parameters.orgType) url.searchParams.append('o_type', parameters.orgType);
    if (parameters.orgClass) url.searchParams.append('o_class', parameters.orgClass);
    if (parameters.orgUnit) url.searchParams.append('o_unit', parameters.orgUnit);
    if (parameters.orgName) url.searchParams.append('o_name', parameters.orgName);
    if (parameters.neighbouringOrgCodes?.length) url.searchParams.append('n_codes', parameters.neighbouringOrgCodes.join('|'));

    if (parameters.targetParentOrgCode) url.searchParams.append('tp_code', parameters.targetParentOrgCode);
    if (parameters.targetOrgCode) url.searchParams.append('t_code', parameters.targetOrgCode);
    if (parameters.targetOrgName) url.searchParams.append('t_name', parameters.targetOrgName);
    if (parameters.targetNeighbouringOrgCodes?.length) url.searchParams.append('tn_codes', parameters.targetNeighbouringOrgCodes.join('|'));

    window.open(url.href, '_blank');
  }
}
