import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker, MatDatepickerInput } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import moment, { Moment, MomentInput } from 'moment/moment';
import { MatTooltip } from '@angular/material/tooltip';
import { COIN_FORMATS, DefaultControlValueAccessor } from '@coin/shared/util-helpers';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatFormFieldClass } from '../../input.types';

@Component({
  selector: 'coin-month-input',
  imports: [FormsModule, MatDatepicker, MatDatepickerInput, MatFormField, MatInput, MatLabel, MatSuffix, AsyncPipe, MatIcon, TranslateModule, MatTooltip],
  standalone: true,
  templateUrl: './month-input.component.html',
  styleUrl: './month-input.component.scss',
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useFactory: (injector: Injector) => {
        const inheritedFormats = injector.get(MAT_DATE_FORMATS, null, { skipSelf: true, optional: true });
        const display = inheritedFormats?.display ?? COIN_FORMATS.display;
        const dateInput = (display.dateInput as string).replaceAll(/d+[./]/gi, '');
        return { ...COIN_FORMATS, display: { ...display, dateInput } };
      },
      deps: [Injector]
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthInputComponent),
      multi: true
    }
  ]
})
export class MonthInputComponent extends DefaultControlValueAccessor<Moment> implements OnInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() matFormFieldClass: MatFormFieldClass = 'customer-grey';
  @Input() boldValue = false;
  @Input() min: Moment;
  @Input() max: Moment;

  @Input() updateOn: 'input' | 'blur' = 'input';

  constructor() {
    super();
  }

  writeValue(value: MomentInput) {
    if (this.isInvalidDate(value)) {
      super.writeValue(null);
    } else {
      super.writeValue(moment(value ?? null).utc());
    }
  }

  protected onInputBlur(value: string) {
    this.onTouch(this.value);
    if (this.updateOn === 'blur' && !this.isSame(value)) {
      this.value = moment(value);
    }
  }

  protected onInputChange(value: string) {
    if (this.updateOn === 'input' && !this.isSame(value)) {
      this.value = moment(value);
    }
  }

  public isSame(value: MomentInput): boolean {
    if (!value && !this.value) return true;
    return !!moment(this.value)?.isSame(value, 'month');
  }

  public setMonthAndYear(selectedDate: Moment, datepicker: MatDatepicker<Moment>) {
    const firstOfMonth = moment(this.value);
    firstOfMonth.month(selectedDate.month());
    firstOfMonth.year(selectedDate.year());
    firstOfMonth.startOf('month');

    this.value = firstOfMonth;
    this.onChange(this.value);
    datepicker.close();
  }

  private isInvalidDate(value: MomentInput): boolean {
    // BE sometimes populates empty date fields with this default date string, causing the dates to appear as though they have a valid value.
    if (moment(value).toISOString() === moment('0001-01-01T00:00:00.000Z').toISOString()) {
      console.warn('Invalid date string provided!');
      return true;
    }
    return false;
  }
}
