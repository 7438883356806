import { LetterType, SeasonProcessState, SeasonTypeEnum } from '@coin/shared/util-enums';

export function getLetterTypeBySeasonType(seasonType: SeasonTypeEnum, currentProcessStepId: string): LetterType {
  switch (seasonType) {
    case SeasonTypeEnum.SOG:
      return LetterType.AdherenceLetter;
    case SeasonTypeEnum.IncentiveV2:
      return currentProcessStepId === SeasonProcessState.TargetAchievement ? LetterType.TargetAchievementForm : LetterType.TargetAgreementForm;
    case SeasonTypeEnum.Merit:
      return LetterType.MeritAllocation;
    case SeasonTypeEnum.SpecialPaymentCash:
    case SeasonTypeEnum.SpecialPaymentEquity:
      return LetterType.SpecialPaymentLetter;
    default:
      return undefined;
  }
}
