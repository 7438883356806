import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { MatInput, MatLabel } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';
import { MatTooltip } from '@angular/material/tooltip';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatFormField } from '@angular/material/form-field';
import { MatFormFieldClass } from '../../input.types';

@Component({
  selector: 'coin-v2-text-area',
  templateUrl: './v2-text-area.component.html',
  styleUrls: ['./v2-text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatInput, FormsModule, MatIconModule, MatTooltip, MatFormField, MatLabel, TextFieldModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2TextAreaComponent),
      multi: true
    }
  ]
})
export class V2TextAreaComponent extends DefaultControlValueAccessor<string> {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() rows: number | 'auto' = 'auto';
  @Input() readonly?: boolean = false;
  @Input() matFormFieldClass: MatFormFieldClass = 'customer-grey';
}
