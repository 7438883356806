<coin-v2-dialog-frame [header]="getTranslationKey('headline') | translate">
  <div class="body-container">
    <div class="dialog-content">
      <div class="explanation-wrapper">
        <div class="head">
          <h5>{{ 'dialog.confirm-checklist.explanation' | translate }}</h5>
        </div>
        <div class="content">
          <p class="explanation">{{ getTranslationKey('explanation') | translate }}</p>
        </div>
      </div>
      <div class="checklist-wrapper">
        <div class="head">
          <h5>{{ 'dialog.confirm-checklist.checklist' | translate }}</h5>
        </div>
        <div class="content">
          <ul class="checklist">
            @for (item of checklist; track item) {
              <li>
                <p>{{ item }}</p>
              </li>
            }
            @if (!checklist.length) {
              <div>
                <div class="no-items-warning">
                  <mat-icon>warning</mat-icon>
                  <span>No checklist items. Please add the first translation:</span>
                </div>
                <p>{{ getTranslationKey('checklist-item-1') }}</p>
              </div>
            }
          </ul>
        </div>
      </div>
    </div>
  </div>
  <coin-v2-dialog-footer color="white">
    <coin-v2-checkbox [formControl]="checkListControl" [label]="'dialog.confirm-checklist.accept-checklist' | translate" />
    <div class="action-buttons">
      <coin-v2-button iconPos="right" [text]="'general.btnClose' | translate" (click)="cancel()" type="secondary" icon="close"> </coin-v2-button>
      <coin-v2-button iconPos="right" [text]="'general.accept' | translate" [disabled]="checkListControl.value === false" (click)="accept()" type="primary" icon="chevron_right">
      </coin-v2-button>
    </div>
  </coin-v2-dialog-footer>
</coin-v2-dialog-frame>
