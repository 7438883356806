/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  ApprovalWorkflowsWorkflowFactoriesCreateWorkflowFactoryModel,
  ApprovalWorkflowsWorkflowFactoriesUpdateWorkflowFactoryModel,
  ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel,
  ApprovalWorkflowsWorkflowFactoryRulesCreateWorkflowFactoryRuleModel,
  ApprovalWorkflowsWorkflowFactoryRulesUpdateWorkflowFactoryRuleModel,
  ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel,
  SharedEntitySearchScopeModel,
  SharedPageModelOfApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel,
  SharedPageModelOfApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel,
  SharedPageModelOfApprovalWorkflowsWorkflowStepsWorkflowStepModel
} from '@coin/shared/util-models';

import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

export interface ApprovalWorkflowsAdminServiceCreateWorkflowFactoryRuleWorkflowFactoryRulesRequestParams {
  /** The unique id of the workflow-factory to create the workflow-factory-rule for. */
  workflowFactoryId: string;
  /** The data to create the workflow-factory-rule with. */
  approvalWorkflowsWorkflowFactoryRulesCreateWorkflowFactoryRuleModel?: ApprovalWorkflowsWorkflowFactoryRulesCreateWorkflowFactoryRuleModel;
}

export interface ApprovalWorkflowsAdminServiceCreateWorkflowFactoryWorkflowFactoriesRequestParams {
  /** The data to create the workflow-factory with. */
  approvalWorkflowsWorkflowFactoriesCreateWorkflowFactoryModel?: ApprovalWorkflowsWorkflowFactoriesCreateWorkflowFactoryModel;
}

export interface ApprovalWorkflowsAdminServiceDeleteWorkflowFactoryRuleWorkflowFactoryRulesRequestParams {
  /** The unique id of the workflow-factory to delete the workflow-factory-rule of. */
  workflowFactoryId: string;
  /** The unique id of the workflow-factory-rule to delete. */
  id: string;
}

export interface ApprovalWorkflowsAdminServiceDeleteWorkflowFactoryWorkflowFactoriesRequestParams {
  /** The unique id of the workflow-factory to delete. */
  id: string;
}

export interface ApprovalWorkflowsAdminServiceGetRuleEngineFieldValuesWorkflowFactoryRuleEngineRequestParams {
  /** The type of workflow-factory to get field-values for. */
  workflowFactoryType: 'PositionRequest';
  /** The field to get field-values for. */
  field: string;
}

export interface ApprovalWorkflowsAdminServiceGetRuleEngineSearchScopeWorkflowFactoryRuleEngineRequestParams {
  /** The type of workflow-factory to get the search scope for. */
  workflowFactoryType: 'PositionRequest';
}

export interface ApprovalWorkflowsAdminServicePageWorkflowFactoriesWorkflowFactoriesRequestParams {
  /** Query all workflow-factories with specific title. */
  titleStartsWith?: string;
  page?: number;
  size?: number;
  orderBy?: 'Asc' | 'Desc';
  property?: string;
}

export interface ApprovalWorkflowsAdminServicePageWorkflowFactoryRulesWorkflowFactoryRulesRequestParams {
  /** The unique id of the workflow-factory to get a page of workflow-factory-rules of. */
  workflowFactoryId: string;
  /** Query all workflow-factory-rules with titles starting with. */
  titleStartsWith?: string;
  page?: number;
  size?: number;
  orderBy?: 'Asc' | 'Desc';
  property?: string;
}

export interface ApprovalWorkflowsAdminServicePageWorkflowStepsWorkflowStepsRequestParams {
  type2: string;
  /** The type of workflow-steps to filter for. */
  type?: 'PositionRequest';
  page?: number;
  size?: number;
  orderBy?: 'Asc' | 'Desc';
  property?: string;
}

export interface ApprovalWorkflowsAdminServiceUpdateWorkflowFactoryRuleWorkflowFactoryRulesRequestParams {
  /** The unique id of the workflow-factory to update the workflow-factory-rule of. */
  workflowFactoryId: string;
  /** The unique id of the workflow-factory-rule to update. */
  id: string;
  /** The data to update the workflow-factory-rule with. */
  approvalWorkflowsWorkflowFactoryRulesUpdateWorkflowFactoryRuleModel?: ApprovalWorkflowsWorkflowFactoryRulesUpdateWorkflowFactoryRuleModel;
}

export interface ApprovalWorkflowsAdminServiceUpdateWorkflowFactoryWorkflowFactoriesRequestParams {
  /** The unique id of the workflow-factory to update. */
  id: string;
  /** The data to update the workflow-factory with. */
  approvalWorkflowsWorkflowFactoriesUpdateWorkflowFactoryModel?: ApprovalWorkflowsWorkflowFactoriesUpdateWorkflowFactoryModel;
}

@Injectable({
  providedIn: 'root'
})
export class ApprovalWorkflowsAdminService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Creates a workflow-factory-rule for a workflow-factory.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createWorkflowFactoryRuleWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServiceCreateWorkflowFactoryRuleWorkflowFactoryRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>;
  public createWorkflowFactoryRuleWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServiceCreateWorkflowFactoryRuleWorkflowFactoryRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>>;
  public createWorkflowFactoryRuleWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServiceCreateWorkflowFactoryRuleWorkflowFactoryRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>>;
  public createWorkflowFactoryRuleWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServiceCreateWorkflowFactoryRuleWorkflowFactoryRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const workflowFactoryId = requestParameters?.workflowFactoryId;
    if (workflowFactoryId === null || workflowFactoryId === undefined) {
      throw new Error('Required parameter workflowFactoryId was null or undefined when calling createWorkflowFactoryRuleWorkflowFactoryRules.');
    }
    const approvalWorkflowsWorkflowFactoryRulesCreateWorkflowFactoryRuleModel = requestParameters?.approvalWorkflowsWorkflowFactoryRulesCreateWorkflowFactoryRuleModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/${this.configuration.encodeParam({ name: 'workflowFactoryId', value: workflowFactoryId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/rules`;
    return this.httpClient.request<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: approvalWorkflowsWorkflowFactoryRulesCreateWorkflowFactoryRuleModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Creates a workflow-factory.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createWorkflowFactoryWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServiceCreateWorkflowFactoryWorkflowFactoriesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>;
  public createWorkflowFactoryWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServiceCreateWorkflowFactoryWorkflowFactoriesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>>;
  public createWorkflowFactoryWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServiceCreateWorkflowFactoryWorkflowFactoriesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>>;
  public createWorkflowFactoryWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServiceCreateWorkflowFactoryWorkflowFactoriesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const approvalWorkflowsWorkflowFactoriesCreateWorkflowFactoryModel = requestParameters?.approvalWorkflowsWorkflowFactoriesCreateWorkflowFactoryModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories`;
    return this.httpClient.request<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: approvalWorkflowsWorkflowFactoriesCreateWorkflowFactoryModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Deletes a workflow-factory-rule of a workflow-factory.
   * Will update all workflow-factory-rule priorities to account for the now deleted workflow-factory-rule.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteWorkflowFactoryRuleWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServiceDeleteWorkflowFactoryRuleWorkflowFactoryRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any>;
  public deleteWorkflowFactoryRuleWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServiceDeleteWorkflowFactoryRuleWorkflowFactoryRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<any>>;
  public deleteWorkflowFactoryRuleWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServiceDeleteWorkflowFactoryRuleWorkflowFactoryRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<any>>;
  public deleteWorkflowFactoryRuleWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServiceDeleteWorkflowFactoryRuleWorkflowFactoryRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const workflowFactoryId = requestParameters?.workflowFactoryId;
    if (workflowFactoryId === null || workflowFactoryId === undefined) {
      throw new Error('Required parameter workflowFactoryId was null or undefined when calling deleteWorkflowFactoryRuleWorkflowFactoryRules.');
    }
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteWorkflowFactoryRuleWorkflowFactoryRules.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/${this.configuration.encodeParam({ name: 'workflowFactoryId', value: workflowFactoryId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/rules/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Deletes a workflow-factory.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteWorkflowFactoryWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServiceDeleteWorkflowFactoryWorkflowFactoriesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any>;
  public deleteWorkflowFactoryWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServiceDeleteWorkflowFactoryWorkflowFactoriesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<any>>;
  public deleteWorkflowFactoryWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServiceDeleteWorkflowFactoryWorkflowFactoriesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<any>>;
  public deleteWorkflowFactoryWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServiceDeleteWorkflowFactoryWorkflowFactoriesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteWorkflowFactoryWorkflowFactories.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Gets all field-values for the rule-set configuration.
   * Use this together with the search-scope endpoint and same workflow-type to fully configure rule-sets  that can be persisted by the rule-engine-api.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRuleEngineFieldValuesWorkflowFactoryRuleEngine(
    requestParameters?: ApprovalWorkflowsAdminServiceGetRuleEngineFieldValuesWorkflowFactoryRuleEngineRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<string>>;
  public getRuleEngineFieldValuesWorkflowFactoryRuleEngine(
    requestParameters?: ApprovalWorkflowsAdminServiceGetRuleEngineFieldValuesWorkflowFactoryRuleEngineRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<string>>>;
  public getRuleEngineFieldValuesWorkflowFactoryRuleEngine(
    requestParameters?: ApprovalWorkflowsAdminServiceGetRuleEngineFieldValuesWorkflowFactoryRuleEngineRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<string>>>;
  public getRuleEngineFieldValuesWorkflowFactoryRuleEngine(
    requestParameters?: ApprovalWorkflowsAdminServiceGetRuleEngineFieldValuesWorkflowFactoryRuleEngineRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const workflowFactoryType = requestParameters?.workflowFactoryType;
    if (workflowFactoryType === null || workflowFactoryType === undefined) {
      throw new Error('Required parameter workflowFactoryType was null or undefined when calling getRuleEngineFieldValuesWorkflowFactoryRuleEngine.');
    }
    const field = requestParameters?.field;
    if (field === null || field === undefined) {
      throw new Error('Required parameter field was null or undefined when calling getRuleEngineFieldValuesWorkflowFactoryRuleEngine.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factory/types/${this.configuration.encodeParam({ name: 'workflowFactoryType', value: workflowFactoryType, in: 'path', style: 'simple', explode: false, dataType: "'PositionRequest'", dataFormat: undefined })}/rule-engine/field-values/${this.configuration.encodeParam({ name: 'field', value: field, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<Array<string>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Gets the rule-engine search-scope for workflow-factory-rules.
   * Use this to populate the rule-engine dialog with all available fields and types for the specific workflow-type.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRuleEngineSearchScopeWorkflowFactoryRuleEngine(
    requestParameters?: ApprovalWorkflowsAdminServiceGetRuleEngineSearchScopeWorkflowFactoryRuleEngineRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedEntitySearchScopeModel>;
  public getRuleEngineSearchScopeWorkflowFactoryRuleEngine(
    requestParameters?: ApprovalWorkflowsAdminServiceGetRuleEngineSearchScopeWorkflowFactoryRuleEngineRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedEntitySearchScopeModel>>;
  public getRuleEngineSearchScopeWorkflowFactoryRuleEngine(
    requestParameters?: ApprovalWorkflowsAdminServiceGetRuleEngineSearchScopeWorkflowFactoryRuleEngineRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedEntitySearchScopeModel>>;
  public getRuleEngineSearchScopeWorkflowFactoryRuleEngine(
    requestParameters?: ApprovalWorkflowsAdminServiceGetRuleEngineSearchScopeWorkflowFactoryRuleEngineRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const workflowFactoryType = requestParameters?.workflowFactoryType;
    if (workflowFactoryType === null || workflowFactoryType === undefined) {
      throw new Error('Required parameter workflowFactoryType was null or undefined when calling getRuleEngineSearchScopeWorkflowFactoryRuleEngine.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factory/types/${this.configuration.encodeParam({ name: 'workflowFactoryType', value: workflowFactoryType, in: 'path', style: 'simple', explode: false, dataType: "'PositionRequest'", dataFormat: undefined })}/rule-engine/search-scope`;
    return this.httpClient.request<SharedEntitySearchScopeModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Gets a page of workflow-factories.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pageWorkflowFactoriesWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServicePageWorkflowFactoriesWorkflowFactoriesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>;
  public pageWorkflowFactoriesWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServicePageWorkflowFactoriesWorkflowFactoriesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>>;
  public pageWorkflowFactoriesWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServicePageWorkflowFactoriesWorkflowFactoriesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>>;
  public pageWorkflowFactoriesWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServicePageWorkflowFactoriesWorkflowFactoriesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const titleStartsWith = requestParameters?.titleStartsWith;
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const orderBy = requestParameters?.orderBy;
    const property = requestParameters?.property;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (titleStartsWith !== undefined && titleStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>titleStartsWith, 'TitleStartsWith');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }
    if (orderBy !== undefined && orderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orderBy, 'OrderBy');
    }
    if (property !== undefined && property !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>property, 'Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories`;
    return this.httpClient.request<SharedPageModelOfApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Gets a page of workflow-factory-rules of a specific workflowFactoryId.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pageWorkflowFactoryRulesWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServicePageWorkflowFactoryRulesWorkflowFactoryRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>;
  public pageWorkflowFactoryRulesWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServicePageWorkflowFactoryRulesWorkflowFactoryRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>>;
  public pageWorkflowFactoryRulesWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServicePageWorkflowFactoryRulesWorkflowFactoryRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>>;
  public pageWorkflowFactoryRulesWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServicePageWorkflowFactoryRulesWorkflowFactoryRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const workflowFactoryId = requestParameters?.workflowFactoryId;
    if (workflowFactoryId === null || workflowFactoryId === undefined) {
      throw new Error('Required parameter workflowFactoryId was null or undefined when calling pageWorkflowFactoryRulesWorkflowFactoryRules.');
    }
    const titleStartsWith = requestParameters?.titleStartsWith;
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const orderBy = requestParameters?.orderBy;
    const property = requestParameters?.property;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (titleStartsWith !== undefined && titleStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>titleStartsWith, 'TitleStartsWith');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }
    if (orderBy !== undefined && orderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orderBy, 'OrderBy');
    }
    if (property !== undefined && property !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>property, 'Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/${this.configuration.encodeParam({ name: 'workflowFactoryId', value: workflowFactoryId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/rules`;
    return this.httpClient.request<SharedPageModelOfApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Gets a page of workflow-steps of specific type.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pageWorkflowStepsWorkflowSteps(
    requestParameters?: ApprovalWorkflowsAdminServicePageWorkflowStepsWorkflowStepsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfApprovalWorkflowsWorkflowStepsWorkflowStepModel>;
  public pageWorkflowStepsWorkflowSteps(
    requestParameters?: ApprovalWorkflowsAdminServicePageWorkflowStepsWorkflowStepsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfApprovalWorkflowsWorkflowStepsWorkflowStepModel>>;
  public pageWorkflowStepsWorkflowSteps(
    requestParameters?: ApprovalWorkflowsAdminServicePageWorkflowStepsWorkflowStepsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfApprovalWorkflowsWorkflowStepsWorkflowStepModel>>;
  public pageWorkflowStepsWorkflowSteps(
    requestParameters?: ApprovalWorkflowsAdminServicePageWorkflowStepsWorkflowStepsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const type2 = requestParameters?.type2;
    if (type2 === null || type2 === undefined) {
      throw new Error('Required parameter type2 was null or undefined when calling pageWorkflowStepsWorkflowSteps.');
    }
    const type = requestParameters?.type;
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const orderBy = requestParameters?.orderBy;
    const property = requestParameters?.property;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (type !== undefined && type !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>type, 'type');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }
    if (orderBy !== undefined && orderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orderBy, 'OrderBy');
    }
    if (property !== undefined && property !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>property, 'Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factory/types/${this.configuration.encodeParam({ name: 'type2', value: type, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/steps`;
    return this.httpClient.request<SharedPageModelOfApprovalWorkflowsWorkflowStepsWorkflowStepModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Updates a workflow-factory-rule of a workflow-factory.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateWorkflowFactoryRuleWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServiceUpdateWorkflowFactoryRuleWorkflowFactoryRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>;
  public updateWorkflowFactoryRuleWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServiceUpdateWorkflowFactoryRuleWorkflowFactoryRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>>;
  public updateWorkflowFactoryRuleWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServiceUpdateWorkflowFactoryRuleWorkflowFactoryRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>>;
  public updateWorkflowFactoryRuleWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminServiceUpdateWorkflowFactoryRuleWorkflowFactoryRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const workflowFactoryId = requestParameters?.workflowFactoryId;
    if (workflowFactoryId === null || workflowFactoryId === undefined) {
      throw new Error('Required parameter workflowFactoryId was null or undefined when calling updateWorkflowFactoryRuleWorkflowFactoryRules.');
    }
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateWorkflowFactoryRuleWorkflowFactoryRules.');
    }
    const approvalWorkflowsWorkflowFactoryRulesUpdateWorkflowFactoryRuleModel = requestParameters?.approvalWorkflowsWorkflowFactoryRulesUpdateWorkflowFactoryRuleModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/${this.configuration.encodeParam({ name: 'workflowFactoryId', value: workflowFactoryId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/rules/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: approvalWorkflowsWorkflowFactoryRulesUpdateWorkflowFactoryRuleModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Updates a workflow-factory.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateWorkflowFactoryWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServiceUpdateWorkflowFactoryWorkflowFactoriesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>;
  public updateWorkflowFactoryWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServiceUpdateWorkflowFactoryWorkflowFactoriesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>>;
  public updateWorkflowFactoryWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServiceUpdateWorkflowFactoryWorkflowFactoriesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>>;
  public updateWorkflowFactoryWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminServiceUpdateWorkflowFactoryWorkflowFactoriesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateWorkflowFactoryWorkflowFactories.');
    }
    const approvalWorkflowsWorkflowFactoriesUpdateWorkflowFactoryModel = requestParameters?.approvalWorkflowsWorkflowFactoriesUpdateWorkflowFactoryModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: approvalWorkflowsWorkflowFactoriesUpdateWorkflowFactoryModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
}
