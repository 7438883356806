<mat-form-field
  [matTooltip]="errorTooltip$ | async"
  [matTooltipDisabled]="!touched()"
  matTooltipClass="error-tooltip"
  class="lib-input coin-form-field-outline coin-form-field-outline--{{ matFormFieldClass }} no-margin"
  appearance="outline"
  floatLabel="always">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <mat-select
    [panelClass]="getPanelHeightClass()"
    #matSelect="matSelect"
    [placeholder]="placeholder"
    [multiple]="multiple"
    [(ngModel)]="value"
    [disabled]="disabled"
    (closed)="onTouch(value)"
    [compareWith]="compareWith"
    (openedChange)="onOpenedChange($event)">
    <!--Custom Trigger -->
    <mat-select-trigger>
      @switch (selectTriggerType) {
        @case ('template') {
          <ng-container *ngTemplateOutlet="selectedValueTemplate"></ng-container>
        }
        @case ('multiple-values') {
          <p>
            <i>
              {{ 'general.dropdown-selected' | translate }} <b>{{ value.length }}</b>
            </i>
          </p>
        }
        @default {
          {{ matSelect.triggerValue || ('general.unknown-value' | translate) }}
        }
      }
    </mat-select-trigger>

    <!--Search Input -->
    @if (search.observed) {
      <div class="filter-container">
        <coin-v2-text-input
          [loading]="loading"
          [label]="'general.dropdown-search-label' | translate"
          [formControl]="searchControl"
          [inputDebounce]="searchDebounce"
          (keydown)="$event.stopPropagation()"
          [clearable]="true"></coin-v2-text-input>
      </div>
      <!-- Enables search opening with no available options -->
      @if (!options.length) {
        <mat-option hidden></mat-option>
      }
    }

    <!--Dropdown Options -->
    @if (!isRequired && !multiple) {
      <mat-option [value]="null">---</mat-option>
    }
    @for (option of options(); track option) {
      <mat-option [value]="option.value" [disabled]="option.disabled">
        <ng-container *ngTemplateOutlet="option.template"></ng-container>
      </mat-option>
    }

    <!-- Temporary Workaround: provide already selected values as options so that they don't deselect when gone -->
    @if (multiple) {
      @for (item of value; track item) {
        <mat-option hidden [value]="item"></mat-option>
      }
    }

    @if (bottomTemplate) {
      <div class="bottom-container">
        <ng-container *ngTemplateOutlet="bottomTemplate"></ng-container>
      </div>
    }
  </mat-select>
</mat-form-field>
