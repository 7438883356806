export enum PermissionResource {
  All = 'All',
  AdminApp = 'AdminApp',
  CustomerApp = 'CustomerApp',
  EquityApp = 'EquityApp',
  ReOrganisationApp = 'ReOrganisationApp',
  Announcements = 'Announcements',
  Allocation = 'Allocation',
  AllocationSettings = 'AllocationSettings',
  ApplicationSettings = 'ApplicationSettings',
  Analytics = 'Analytics',
  BudgetCalculation = 'BudgetCalculation',
  CMS = 'CMS',
  Communication = 'Communication',
  Compensation = 'Compensation',
  Distribution = 'Distribution',
  DistributionPreparation = 'DistributionPreparation',
  Directs = 'Directs',
  Employees = 'Employees',
  Emulation = 'Emulation',
  Factors = 'Factors',
  Formulas = 'Formulas',
  GeneralCluster = 'GeneralCluster',
  HeadcountSettings = 'HeadcountSettings',
  KPIList = 'KPIList',
  KPIValues = 'KPIValues',
  Letters = 'Letters',
  Notifications = 'Notifications',
  MasterData = 'MasterData',
  News = 'News',
  Mails = 'Mails',
  ParameterInput = 'ParameterInput',
  PlanAdministration = 'PlanAdministration',
  PlanAssignment = 'PlanAssignment',
  PlanDesign = 'PlanDesign',
  PlanManagement = 'PlanManagement',
  PlanAchievementValues = 'PlanAchievementValues',
  PlanAchievementProcess = 'PlanAchievementProcess',
  Populations = 'Populations',
  Reassignments = 'Reassignments',
  ReassignmentCandidates = 'ReassignmentCandidates',
  Records = 'Records',
  RoleManagement = 'RoleManagement',
  EndpointGroups = 'EndpointGroups',
  RuleSets = 'RuleSets',
  Seasons = 'Seasons',
  Simulations = 'Simulations',
  SpecialPayment = 'SpecialPayment',
  SpecialPaymentEquity = 'SpecialPaymentEquity',
  Tasks = 'Tasks',
  Templates = 'Templates',
  TransactionStatus = 'TransactionStatus',
  Translations = 'Translations',
  TopNodes = 'TopNodes',
  UserManagement = 'UserManagement',
  Workflows = 'Workflows',
  Todos = 'Todos',
  GBR = 'GBR',
  GBRForms = 'GBRForms',
  GBRRepresentative = 'GBRRepresentative',
  GbrSeasonAdmin = 'GbrSeasonAdmin',
  HrPartnerApp = 'HrPartnerApp',
  AllocationHR = 'AllocationHR',
  Mercer = 'Mercer',
  SuccessionManagementHR = 'SuccessionManagementHR',
  SuccessionManagementAdmin = 'SuccessionManagementAdmin',
  PositionEvaluationHR = 'PositionEvaluationHR',
  PositionEvaluation = 'PositionEvaluation',
  StandingPositionEvaluation = 'StandingPositionEvaluation', // TODO clarify final permissions with BE
  StandingPositionEvaluationHR = 'StandingPositionEvaluationHr',
  StandingPositionEvaluationAdmin = 'StandingPositionEvaluationAdmin',
  PreviewAllocation = 'PreviewAllocation',
  Organisation = 'Organisation',
  OrganisationPlanning = 'OrganisationPlanning',
  OrganisationPlanningAdmin = 'OrganisationPlanningAdmin',
  OrganisationManagementUser = 'OrganisationManagementUser',
  OrganisationManagementAdmin = 'OrganisationManagementAdmin',
  EmploymentConditionLibraryUser = 'EmploymentConditionLibraryUser',
  EmploymentConditionLibraryAdmin = 'EmploymentConditionLibraryAdmin',
  EmploymentConditionLibraryRestricted = 'EmploymentConditionLibraryRestricted',
  EmploymentConditionLibraryGenerator = 'EmploymentConditionLibraryGenerator',
  HeadcountApprovalHr = 'HeadcountApprovalHr',
  HeadcountApprovalAdmin = 'HeadcountApprovalAdmin',
  IncentiveSeasonHr = 'IncentiveSeasonHr',
  IncentiveRecordAdmin = 'IncentiveRecordAdmin',
  CAndB = 'CAndB',
  SignatureAuthorizationAdmin = 'SignatureAuthorizationAdmin',
  RestructuringAdmin = 'RestructuringAdmin',
  RestructuringAdminRestricted = 'RestructuringAdminRestricted',
  RestructuringHr = 'RestructuringHr',
  RestructuringConditionLibraryAdmin = 'RestructuringConditionLibraryAdmin',
  RestructuringConditionLibraryRestricted = 'RestructuringConditionLibraryRestricted',
  RestructuringSignatureAuthorizationAdmin = 'RestructuringSignatureAuthorizationAdmin',
  RestructuringConditionLibrarySuperAdmin = 'RestructuringConditionLibrarySuperAdmin',
  EmploymentConditionLibrarySuperAdmin = 'EmploymentConditionLibrarySuperAdmin'
}

export enum PermissionAction {
  All = 'All',
  Read = 'Read',
  Create = 'Create',
  Download = 'Download',
  Delete = 'Delete',
  Update = 'Update'
}
