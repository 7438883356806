import { signal } from '@angular/core';
import { TransactionState } from '@coin/shared/util-enums';
import { RoundOperations } from '@coin/shared/util-helpers';
import { Metadata, TransactionStatus, TransactionStatusMetadata } from '@coin/shared/util-models';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, concat, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, delay, filter, finalize, map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TransactionCacheService } from './transaction-cache.service';
import { TransactionService } from './transaction.service';

export class TransactionCalculationOperations {
  private readonly _calculating = signal<boolean>(undefined);
  private readonly _displayAsList = signal<boolean>(undefined);
  private readonly _showProgressCount = signal<boolean>(true);
  private readonly _calcProgress = signal(0);
  private readonly _transactionStatus = signal<TransactionStatus>(undefined);
  private readonly _processTitle = signal<string>(undefined);

  public get calculating() {
    return this._calculating();
  }

  public get displayAsList() {
    return this._displayAsList();
  }

  public get showProgressCount() {
    return this._showProgressCount();
  }

  public get calcProgress() {
    return this._calcProgress();
  }

  public get transactionStatus() {
    return this._transactionStatus();
  }

  public get processTitle() {
    return this._processTitle();
  }

  public stopCalc$ = new Subject<TransactionStatus>();
  private load$ = new BehaviorSubject('');

  private get defaultTimeout(): number {
    return this.transactionCacheService?.timeout || 3 * 60_000;
  }

  constructor(
    private transactionService: TransactionService,
    private toast: ToastrService,
    private transactionCacheService?: TransactionCacheService
  ) {}

  private isRecent(status: TransactionStatus<Metadata>, timeout = this.defaultTimeout): boolean {
    return !!status?.finishedAt && (new Date(status.finishedAt).getFullYear() === 1 || new Date(status.finishedAt).getTime() + timeout > Date.now());
  }

  private canShowNotification(status: TransactionStatus<Metadata>): boolean {
    // Only show toasts for transactions that finished recently and have not already been shown.
    // Recent transactions will not be shown because they are cached while ancient transactions will not be shown because of their age.
    // There is a possibility however, that notifications are shown multiple times, if e.g. the page is reloaded before finishedAt is old enough.
    // I don't expect this to happen often enough to be considered a problem though.
    return this.isRecent(status) && !this.isInTransactionIdCache(status);
  }

  private addToTransactionIdCache(status: TransactionStatus<Metadata>): void {
    this.transactionCacheService?.set(status?.transactionId);
  }

  private isInTransactionIdCache(status: TransactionStatus<Metadata>): boolean {
    return !!this.transactionCacheService?.has(status?.transactionId);
  }

  public startProcessAsList = <T extends Metadata>(
    getTransactionIdReq$: Observable<Partial<TransactionStatus<T>>>,
    showStatus = false,
    emitUpdates = false,
    displayAsList = true,
    showProgressCount = true
  ) => this.startProcess(getTransactionIdReq$, showStatus, emitUpdates, displayAsList, showProgressCount);

  public startProcess = <T extends Metadata>(
    getTransactionIdReq$: Observable<Partial<TransactionStatus<T>>>,
    showStatus = false,
    emitUpdates = false,
    displayAsList = false,
    showProgressCount = true,
    processTitle: string = undefined
  ): Observable<T> =>
    getTransactionIdReq$.pipe(
      tap(() => {
        this._calculating.set(true);
        this._displayAsList.set(displayAsList);
        this._calcProgress.set(0);
        this._showProgressCount.set(showProgressCount);
      }),
      switchMap(({ transactionId }) => this.pollTransactionStatus(transactionId, showStatus, emitUpdates, null, processTitle) as Observable<TransactionStatus<T>>),
      map(transaction => transaction.metadata),
      catchError(e => {
        this.toast.error('Process failed.');
        return throwError(e);
      }),
      finalize(() => {
        this._calculating.set(false);
        this._displayAsList.set(false);
      })
    );

  public startProcessAndRetrieveTransaction = <T extends Metadata>(
    getTransactionIdReq$: Observable<Partial<TransactionStatus<T>>>,
    showStatus = false,
    emitUpdates = false,
    displayAsList = false,
    showProgressCount = true
  ): Observable<TransactionStatus<T>> =>
    getTransactionIdReq$.pipe(
      tap(() => {
        this._calculating.set(true);
        this._displayAsList.set(displayAsList);
        this._calcProgress.set(0);
        this._showProgressCount.set(showProgressCount);
      }),
      switchMap(({ transactionId }) => this.pollTransactionStatus(transactionId, showStatus, emitUpdates) as Observable<TransactionStatus<T>>),
      map(transaction => transaction),
      catchError(e => {
        this.toast.error('Process failed.');
        return throwError(e);
      }),
      finalize(() => {
        this._calculating.set(false);
        this._displayAsList.set(false);
      })
    );

  public startCustomProcess = <T extends Metadata>(
    getTransactionIdReq$: Observable<{ transactionId: string }>,
    customObservable$: Observable<unknown>,
    showStatus = false,
    emitUpdates = false,
    displayAsList = false
  ): Observable<T> =>
    getTransactionIdReq$.pipe(
      tap(() => {
        this._calculating.set(true);
        this._displayAsList.set(displayAsList);
        this._calcProgress.set(0);
      }),
      switchMap(({ transactionId }) => this.pollTransactionStatus(transactionId, showStatus, emitUpdates, customObservable$) as Observable<TransactionStatus<T>>),
      map(transaction => transaction.metadata),
      catchError(e => {
        this.toast.error('Process failed.');
        return throwError(e);
      }),
      finalize(() => {
        this._calculating.set(false);
        this._displayAsList.set(false);
      })
    );

  public start = <T extends TransactionStatus>(
    getTransactionIdReq$: Observable<T>,
    showStatus = false,
    emitUpdates = false,
    displayAsList = false,
    showProgressCount = true
  ): Observable<T> =>
    getTransactionIdReq$.pipe(
      tap(() => {
        this._calculating.set(true);
        this._displayAsList.set(displayAsList);
        this._calcProgress.set(0);
        this._showProgressCount.set(showProgressCount);
      }),
      switchMap(({ transactionId }) => this.pollTransactionStatus(transactionId, showStatus, emitUpdates) as Observable<T>),
      catchError(e => {
        if (showStatus) {
          this.toast.error('Process failed.');
        }
        throw e;
      }),
      finalize(() => {
        this._calculating.set(false);
        this._displayAsList.set(false);
      })
    );

  private pollTransactionStatus(
    transactionId: string,
    showStatus = true,
    emitUpdates = false,
    customObservable: Observable<unknown> = null,
    processTitle: string = undefined
  ): Observable<TransactionStatus<Metadata>> {
    const getStatus$ = customObservable ?? this.transactionService.getTransactionStatusByTransactionId(transactionId);

    const pollAgain$ = of('').pipe(
      // TODO refactor as takeWhile?
      // Necessary for logic to work and not trigger the es lint rule
      // eslint-disable-next-line
      takeUntil(this.stopCalc$),
      delay(2000),
      tap(() => this.load$.next('')),
      skip(1) // don't use '' value in futher st eps
    );

    const poll$ = concat(getStatus$, pollAgain$);

    return this.load$.pipe(
      // TODO refactor as takeWhile?
      // Necessary for logic to work and not trigger the es lint rule
      // eslint-disable-next-line
      takeUntil(this.stopCalc$),
      concatMap(() => poll$),
      tap((status: TransactionStatus<TransactionStatusMetadata>) => {
        this._transactionStatus.set(status);
        this._processTitle.set(processTitle ?? status?.processTitle);
      }),
      map((status: TransactionStatus<TransactionStatusMetadata>) => {
        this._calculating.set([TransactionState.Started, TransactionState.Running].includes(status.state));
        this._calcProgress.set(RoundOperations.round(status?.totalCount ? (status.progressCount / status.totalCount) * 100 : 0));
        return status;
      }),
      switchMap((status: TransactionStatus<TransactionStatusMetadata>) => {
        const isFinished = [TransactionState.Failed, TransactionState.Cancelled, TransactionState.Finished].includes(status?.state);
        if (!isFinished && emitUpdates) return of(status);

        return of(status).pipe(
          filter(() => isFinished),
          switchMap((status: TransactionStatus<TransactionStatusMetadata>) => this.displayToast(status, showStatus)),
          tap(status => this.addToTransactionIdCache(status)),
          tap(() => this.stopTransactionStatusCheck()),
          catchError(e => {
            this.stopTransactionStatusCheck();
            return throwError(e);
          })
        );
      })
    );
  }

  public checkTransactionStatus(transactionId: string, showStatus = true): Observable<Metadata> {
    return this.pollTransactionStatus(transactionId, showStatus).pipe(map(status => status?.metadata));
  }

  public checkSeasonTransactionStatus(seasonId: string, showStatus = false): Observable<Metadata> {
    return this.transactionService.getTransactionStatusByEntityId<TransactionStatusMetadata>(seasonId).pipe(
      switchMap(transaction => this.displayToast(transaction, showStatus)),
      filter(transaction => !!transaction),
      filter(transaction => transaction.state === TransactionState.Started || transaction.state === TransactionState.Running),
      switchMap(transaction => this.checkTransactionStatus(transaction.transactionId, showStatus))
    );
  }

  private displayToast(status: TransactionStatus<TransactionStatusMetadata>, showStatus: boolean): Observable<TransactionStatus<TransactionStatusMetadata>> {
    if (this.canShowNotification(status) && showStatus) {
      status?.metadata?.failures
        ?.map(failure => failure.failureReason)
        .filter(reason => !!reason)
        .forEach(reason => this.toast.error(reason));

      status?.metadata?.warnings
        ?.map(warning => warning.failureReason)
        .filter(reason => !!reason)
        .forEach(reason => this.toast.warning(reason));

      switch (status?.state) {
        case TransactionState.Finished:
          this.toast.success('Process successful.');
          break;
        case TransactionState.Failed:
          if (!status?.metadata?.failures?.length) {
            this.toast.error(status.metadata?.failureReason ?? 'Process failed.');
          }

          return throwError(() => new Error('Process failed.'));
        case TransactionState.Cancelled:
          this.toast.info('Process cancelled.');
          break;
      }
    }

    return of(status);
  }

  public cancelTransactionProcess(): void {
    this.transactionService
      .cancelTransactionProcess(this.transactionStatus.transactionId)
      .pipe(
        tap(transactionStatus => {
          this._transactionStatus.set(transactionStatus);
          this._processTitle.set(transactionStatus?.processTitle);
        }),
        tap(() => this.stopTransactionStatusCheck())
      )
      .subscribe();
  }

  public destroy(): void {
    this.stopCalc$.next(this.transactionStatus);
    this.stopCalc$.complete();
  }

  public stopTransactionStatusCheck(): void {
    this.stopCalc$.next(this.transactionStatus);
    this._calculating.set(false);
  }
}
