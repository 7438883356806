/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Return Model of Access Restriction
 */
export interface AccessRestrictionsAccessRestrictionModel {
  id?: string;
  accessTypes?: Array<AccessRestrictionsAccessRestrictionModelAccessTypesEnum> | null;
  operationTypes?: Array<AccessRestrictionsAccessRestrictionModelOperationTypesEnum> | null;
  isAdminRestriction?: boolean;
}
export enum AccessRestrictionsAccessRestrictionModelAccessTypesEnum {
  System = 'System',
  Admin = 'Admin',
  User = 'User'
}
export enum AccessRestrictionsAccessRestrictionModelOperationTypesEnum {
  Read = 'Read',
  Write = 'Write'
}
