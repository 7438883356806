<div class="top-part" [class.responsive]="showSmallScreenDropdown">
  <div class="selection-part" [class.disabled]="disabled">
    @for (tab of tabs; track tab; let i = $index) {
      @if (!tab.hidden) {
        <button
          class="no-style selection-item"
          [ngClass]="{ 'tab-selected': tab.name === selectedTab?.name || tab.isSelected, 'tab-disabled': tab.isDisabled }"
          [matTooltip]="tab.tooltip | translate"
          [matTooltipDisabled]="!tab.isDisabled"
          (click)="changeSelection(tab, i)">
          {{ tab.name | translate }}
        </button>
      }
    }
  </div>
  <coin-v2-dropdown
    matFormFieldClass="customer-grey"
    required
    [placeholder]="'general.click-to-select' | translate"
    (ngModelChange)="changeSelection($event)"
    [(ngModel)]="selectedTab">
    @for (tab of tabs; track tab.name || tab.title) {
      <ng-template [coinValue]="tab">{{ displayFn(tab) }}</ng-template>
    }
  </coin-v2-dropdown>
</div>
