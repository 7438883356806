<div class="person" [class.light]="light" [class.big]="big" [class.small]="small" [class.tiny]="tiny">
  <!-- Image -->
  @if (employee || showPlaceholder) {
    <div class="avatar-container" [class.show-flag]="employee && showFlag">
      <div class="avatar" slot="start" [class]="{ placeholder: showPlaceholder }">
        @if (imageIcon) {
          <mat-icon>{{ imageIcon }}</mat-icon>
        } @else {
          <coin-employee-picture
            background="none"
            [size]="big ? 'large' : small ? 'small' : tiny ? 'tiny' : 'medium'"
            [gid]="employee?.headGid || employee?.gid"
            size="medium"></coin-employee-picture>
        }
      </div>
      @if (employee?.companyExitDate && showExitDateWarning) {
        <div [matTooltip]="'general.employee-exit-date-msg' | translate: { exitDate: formattedCompanyExitDate }" class="exit-date-warning">
          <mat-icon>event_busy</mat-icon>
        </div>
      }
    </div>
  }

  <div class="additional-icons">
    <mat-icon>chevron_right</mat-icon>
    @if (employee && showFlag && (employee.countryCode || employee.placeOfAction || employee['placeofAction'] || employee.country)) {
      <coin-v2-country-flag [showTooltip]="true" class="flag-icon" [country]="employee | pure: country" size="s"></coin-v2-country-flag>
    }
  </div>

  <div *ngIf="!hideText" class="infos">
    <!-- Top info -->
    @for (item of customTopInfo; track item) {
      <p coinEllipsisTooltip matTooltip class="additional-info">{{ item }}</p>
    }

    <!-- Employee name -->
    <div class="info-header" [ngClass]="{ 'single-line': singleLine || showPlaceholder }">
      @if (showPlaceholder) {
        <p>{{ placeholderText }}</p>
      } @else {
        @if (employee) {
          @if (singleLine) {
            <p coinEllipsisTooltip matTooltip [class.bold]="boldName" [ngClass]="{ smaller: noWrap }">{{ namePrefix }} {{ employee | employeeName }}</p>
          } @else {
            @if (namePrefix) {
              <p [class.bold]="boldName">{{ namePrefix }}</p>
            }
            <p [class.bold]="boldName">{{ employee?.firstname }}</p>
            <p [class.bold]="boldName">{{ employee?.lastname }}</p>
          }
        }
      }

      <ng-template #employeeName>
        @if (employee) {
          @if (singleLine) {
            <p coinEllipsisTooltip matTooltip [class.bold]="boldName" [ngClass]="{ smaller: noWrap }">{{ namePrefix }} {{ employee | employeeName }}</p>
          } @else {
            @if (namePrefix) {
              <p [class.bold]="boldName">{{ namePrefix }}</p>
            }
            <p [class.bold]="boldName">{{ employee?.firstname }}</p>
            <p [class.bold]="boldName">{{ employee?.lastname }}</p>
          }
        }
      </ng-template>

      @if (showActionButton) {
        <coin-action-button (click)="onActionClick()" [small]="true" class="action-button" [matIcon]="actionIcon" [noicon]="true">{{ actionButtonTitle }}</coin-action-button>
      }
    </div>

    <!-- Org code -->
    @if (showOrgCode) {
      @if (!employee?.headGid && !employee?.isHead && (employee?.paOrgCode || employee?.orgCode)) {
        <coin-c-text-input-overlay
          [isEditable]="isOrgCodeEditable"
          class="org-code"
          [ngClass]="{ smaller: noWrap, wrap }"
          [infoHeader]="'general.orgcode-update' | translate"
          [infoText]="'general.change-orgcode' | translate"
          [isSmall]="true"
          [value]="employee?.paOrgCode ?? employee?.orgCode"
          [choicesWithSearch]="true"
          [lazyLoadChoicesFn]="lazyLoadOrgCodesFn"
          (valueChanged)="orgCodeChanged.emit($event)">
        </coin-c-text-input-overlay>
      }
      @if (thirdLineTemplate) {
        <ng-container *ngTemplateOutlet="thirdLineTemplate; context: { employee: employee }"></ng-container>
      }
      @if ((employee?.headGid || employee?.isHead) && employee?.orgCode) {
        <coin-c-text-input-overlay
          [isEditable]="isOrgCodeEditable"
          class="org-code"
          [ngClass]="{ smaller: noWrap, wrap }"
          [infoHeader]="'general.orgcode-update' | translate"
          [infoText]="'general.change-orgcode' | translate"
          [isSmall]="true"
          [value]="employee?.orgCode"
          [choicesWithSearch]="true"
          [lazyLoadChoicesFn]="lazyLoadOrgCodesFn"
          (valueChanged)="orgCodeChanged.emit($event)">
        </coin-c-text-input-overlay>
      }
    }

    <!-- Sub title -->
    @if (subTitle) {
      <p [ngClass]="{ smaller: noWrap }">
        {{ subTitle }}
      </p>
    }

    <!-- Gid -->
    @if (showGid && (employee?.headGid || employee?.gid)) {
      @if (thirdLineTemplate) {
        <ng-container *ngTemplateOutlet="thirdLineTemplate; context: { employee: employee }"></ng-container>
      } @else {
        <p>{{ idKey }}: {{ employee?.headGid || employee?.gid }}</p>
      }
    }

    <!-- Sub info -->
    @for (item of customSubInfo; track item) {
      <p coinEllipsisTooltip matTooltip class="additional-info">{{ item }}</p>
    }
  </div>

  <!-- Roles -->
  @if (roleNames.length) {
    <div class="user-roles">
      @for (name of roleNames; track name) {
        <p class="item-coin" matTooltip coinEllipsisTooltip>
          {{ name }}
        </p>
      }
    </div>
  }

  <!-- Position -->
  @if (positionClass) {
    <div class="position-class" [class.pc-small]="smallerPositionClass">
      <p>PC {{ positionClass }}</p>
    </div>
  }
  @if (!positionClass && showHintIfNoPositionClassSet) {
    <div class="position-class missing" [class.pc-small]="smallerPositionClass">
      <p>{{ 'mercer-support.dialog.no-pc' | translate }}</p>
    </div>
  }
</div>
