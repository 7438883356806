import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { V2TabsComponent } from './v2-tabs.component';
import { V2TabDirective } from './tab/v2-tab.directive';
import { RoutedTabsComponent } from './routed-tabs/routed-tabs.component';

@NgModule({
  declarations: [V2TabsComponent, V2TabDirective, RoutedTabsComponent],
  imports: [CommonModule, MatIconModule],
  exports: [V2TabsComponent, V2TabDirective, RoutedTabsComponent]
})
export class V2TabsModule {}
