import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { map, Observable, of, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { TemplateWithValueDirective } from '@coin/shared/util-directives';
import { EnumToTextPipe, PureFunctionPipe } from '@coin/shared/util-pipes';
import { RuleEngineInput } from '@coin/shared/util-models';
import { V2DropdownComponent } from '../../dropdown/v2-dropdown.component';
import { LightWeightOperator, LightweightRule, LightweightRuleEngineConfig, RuleValue } from '../v2-lightweight-rule-engine.types';
import { emptyRule, isValuelessOperator } from '../v2-lightweight-rule-engine.component';
import { V2CheckboxComponent } from '../../checkbox/v2-checkbox.component';
import { V2DateInputComponent } from '../../date-input/v2-date-input.component';
import { V2TextInputComponent } from '../../text-input/v2-text-input.component';
import { V2LightweightOperatorIconComponent } from '../operator-icon/operator-icon.component';
import { V2NumberInputComponent } from '../../number-input/v2-number-input.component';

type FieldValueMetaData = { filteredFieldValues: string[]; isRawInput: boolean };

@Component({
  selector: 'coin-lightweight-rule',
  templateUrl: './lightweight-rule.component.html',
  styleUrls: ['./lightweight-rule.component.scss'],
  imports: [
    CommonModule,
    V2DropdownComponent,
    MatIconModule,
    MatMenuModule,
    FormsModule,
    TemplateWithValueDirective,
    V2CheckboxComponent,
    V2DateInputComponent,
    V2TextInputComponent,
    V2NumberInputComponent,
    MatTooltipModule,
    PureFunctionPipe,
    TranslateModule,
    V2LightweightOperatorIconComponent,
    EnumToTextPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LightweightRuleComponent {
  @Input({ required: true }) config: LightweightRuleEngineConfig;
  @Input({ required: true }) rule: LightweightRule;
  @Input() deletable = true;
  @Input() translateKeys = false;
  @HostBinding('class.readonly') @Input() disabled = false;
  @Output() delete = new EventEmitter<LightweightRule>();
  @Output() changes = new EventEmitter<void>();
  @Input() scopeSearch$: Subject<string>;

  @HostBinding('class.mat-elevation-z2') shadow = true;

  private selectedRuleConfig: LightweightRuleEngineConfig[string];

  public search$ = new Subject<string>();
  public readonly freeTextOperators = new Set<LightWeightOperator>(['StartsWith', 'NotStartsWith', 'NotEndsWith', 'EndsWith', 'Contains', 'NotContains']);

  get isRuleValueDisabled(): boolean {
    return !this.rule.field || !this.rule.operator;
  }

  get ruleConfig(): LightweightRuleEngineConfig[string] {
    return this.config?.[this.rule?.field] ?? this.selectedRuleConfig;
  }

  public setOperator(operator: LightWeightOperator) {
    if (this.rule.operator !== operator) {
      this.rule.operator = operator;
      this.rule.value = this.isValuelessOperator(operator) ? emptyRule().value : defaultRuleValue(this.ruleConfig.type);
      this.changes.emit();
    }
  }

  public resetOperatorAndValue(): void {
    const { operator, value } = emptyRule();

    this.rule.operator = operator;
    this.rule.value = value;
    this.selectedRuleConfig = this.ruleConfig;

    this.changes.emit();
  }

  public onDeleteClick(): void {
    if (this.deletable) {
      this.delete.emit();
    }
  }

  public isValuelessOperator = isValuelessOperator;

  public castToMetadata = (fieldValues: string[]): Observable<FieldValueMetaData> => {
    if (!fieldValues) return of(null);

    return this.search$.pipe(
      startWith(''),
      map(search => {
        const caseInsensitiveSearch = search.toLowerCase();
        return { filteredFieldValues: fieldValues.filter(value => value.toLowerCase().includes(caseInsensitiveSearch)), isRawInput: !fieldValues.length };
      })
    );
  };
}

function defaultRuleValue(inputType: RuleEngineInput): RuleValue {
  if (inputType === 'System.Boolean') return [false];

  return emptyRule().value;
}
