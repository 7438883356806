import { TreeNode } from '../route-builder';

export const HeadcountModule = {
  segment: 'headcount',
  title: 'home.headcount',
  navigationRoot: true,
  children: {
    HeadcountRequestSettingsComponent: {
      segment: 'settings',
      title: 'headcount-settings.headline',
      icon: 'badge'
    },
    HeadcountWorkflowsComponent: {
      segment: 'workflows',
      title: 'headcount-settings.workflows.headline',
      icon: 'how_to_reg'
    }
  }
} as const satisfies TreeNode;
