<div class="gbr-simple-input-wrapper">
  <mat-card class="mat-mdc-elevation-specific mat-elevation-z4" [class.invalid]="showError">
    <div class="empty">
      @if (display) {
        <div class="selected" @flyInFromBottom>
          <div>
            <p class="label">{{ label | translate }}</p>
            <div>
              @switch (type) {
                @case (types.DateRange) {
                  <p class="value" [innerHTML]="getDateRangeText(value)"></p>
                }
                @case (types.YesNo) {
                  <p class="value">
                    {{ (value ? 'general.yes' : 'general.no') | translate }}
                  </p>
                }
                @default {
                  <p class="value">
                    {{ value }}
                  </p>
                }
              }
            </div>
          </div>
          @if (type === types.DateRange) {
            <div>
              <p class="label">{{ 'gbr.simple-input.range' | translate }}</p>
              <p class="value">{{ getRangeValue(value) }} {{ 'gbr.simple-input.weeks' | translate }}</p>
            </div>
          }
          @if (type === types.DateRange) {
            <div>
              <div class="coin-chip-info organization">
                <p class="title">{{ 'gbr.simple-input.grant' | translate }}:</p>
                <p class="value">{{ getGrantValue(value) }} {{ 'gbr.simple-input.days' | translate }}</p>
              </div>
            </div>
          }
        </div>
      } @else {
        <p class="text">
          {{ label | translate }}
        </p>
        @if (labelTooltip) {
          <mat-icon [matTooltip]="labelTooltip" class="icon-info-gbr">info_outline</mat-icon>
        }
      }

      <div style="flex: 1"></div>
    </div>

    @if (!display) {
      <div class="edit" [class.yes-no-input]="type === types.YesNo">
        @if (type === types.Text) {
          <coin-v2-text-input [placeholder]="'gbr.entry' | translate" [(ngModel)]="value" [disabled]="disabled" (ngModelChange)="userChanges()" />
        }

        @if (type === types.Dropdown) {
          <coin-v2-dropdown required matFormFieldClass="shadow" [(ngModel)]="value" [disabled]="disabled" (ngModelChange)="userChanges()">
            @for(option of options; track option) {
              <ng-template [coinValue]="option">{{ option }}</ng-template>
            }
          </coin-v2-dropdown>
        }

        @if (type === types.DateRange) {
          <mat-form-field
            class="coin-form-field-outline coin-form-field-outline--shadow coin-form-field-outline--date-range"
            appearance="outline"
            floatLabel="always"
            (click)="picker.open()">
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate [placeholder]="'gbr.vacation.period-start' | translate" [(ngModel)]="value.start" [disabled]="disabled" (dateChange)="manualChange()" readonly />
              <input matEndDate [placeholder]="'gbr.vacation.period-end' | translate" [(ngModel)]="value.end" [disabled]="disabled" (dateChange)="manualChange()" readonly />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        }

        @if (type === types.YesNo) {
          <div class="yes-no-option">
            <div class="yes">
              <coin-v2-checkbox [ngModel]="value" [label]="'general.yes' | translate" (ngModelChange)="toggleYesNo(true)" [disabled]="disabled"> </coin-v2-checkbox>
            </div>
            <div class="no">
              <coin-v2-checkbox [label]="'general.no' | translate" [ngModel]="!value" (ngModelChange)="toggleYesNo(false)" [disabled]="disabled"> </coin-v2-checkbox>
            </div>
          </div>
        }
      </div>
    }
  </mat-card>
</div>
