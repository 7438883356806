import { computed, Injectable, Signal, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GlobalEventsService } from '@coin/shared/util-helpers';

@Injectable({
  providedIn: 'root'
})
export class DragDropService {
  private _draggedItem = signal<unknown>(null);

  /** Item that is currently being dragged, or null */
  public draggedItem = this._draggedItem.asReadonly();

  /** Whether an item is currently being dragged */
  public isDragging: Signal<boolean> = computed(() => this._draggedItem() !== null);

  constructor(private events: GlobalEventsService) {
    this.events
      .listen('dragend')
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.stopDragging());
  }

  public startDragging(item: unknown): void {
    this._draggedItem.set(item);
  }

  public stopDragging(): void {
    this._draggedItem.set(null);
  }
}
