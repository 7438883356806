<div class="container" [class.small]="isSmall">
  <p>{{ displayFn ? (value | pure: displayFn) : value }}</p>
  @if (isEditable) {
    <div>
      @if (isEditable) {
        <button #overlayOrigin cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="originClicked()" class="no-style">
          <mat-icon>edit</mat-icon>
          <ng-template
            cdk-connected-overlay
            [cdkConnectedOverlayDisableClose]="false"
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isOverlayVisible"
            [cdkConnectedOverlayHasBackdrop]="true"
            (backdropClick)="toggleOverlay()">
            <div class="overlay-container" #overlay>
              @if (infoHeader) {
                <p class="bold">{{ infoHeader }}</p>
              }
              @if (infoText) {
                <p>{{ infoText }}</p>
              }
              @if (choices?.length || lazyLoadChoicesFn) {
                @if (choicesWithSearch || lazyLoadChoicesFn) {
                  <coin-v2-dropdown #choiceSearch="dropdownSearch" [dataSource]="choices || lazyLoadChoicesFn" [placeholder]="placeholder" [(ngModel)]="editValue">
                    @for (item of choiceSearch.results(); track item) {
                      <ng-template [coinValue]="item">{{ displayFn(item) }}</ng-template>
                    }
                  </coin-v2-dropdown>
                } @else {
                  <coin-v2-dropdown [placeholder]="placeholder" [(ngModel)]="editValue">
                    @for (item of choices; track item) {
                      <ng-template [coinValue]="item">{{ displayFn(item) }}</ng-template>
                    }
                  </coin-v2-dropdown>
                }
              }
              @if (!choices?.length && !lazyLoadChoicesFn) {
                <coin-input [placeholder]="placeholder" [(ngModel)]="editValue"></coin-input>
              }
              <div class="action-row">
                <coin-action-button (click)="cancelClicked()">{{ 'general.cancel' | translate }}</coin-action-button>
                <coin-action-button class="primary" (click)="saveClicked()">{{ 'general.btnSave' | translate }}</coin-action-button>
              </div>
            </div>
          </ng-template>
        </button>
      }
    </div>
  }
</div>
