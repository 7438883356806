import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostBinding, Input, NgZone, Output } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, filter } from 'rxjs/operators';
import { DragDropService } from './drag-drop.service';

@Directive({
  selector: '[coinDrop]',
  exportAs: 'coinDrop',
  standalone: false
})
export class DropDirective {
  @Input() dropDisabled = false;
  /** Emits items dropped into the element */
  @Output() coinDrop = new EventEmitter<unknown>();

  /** Whether an item is currently dragged over the element */
  @HostBinding('class.dragged-over')
  get isDraggedOver(): boolean {
    return this.isDraggedOver$.value;
  }

  private isDraggedOver$ = new BehaviorSubject(false);

  constructor(
    private elem: ElementRef<HTMLElement>,
    private dragDropService: DragDropService,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {
    this.enableDropping();
    this.optimizeChangeDetection();
  }

  private enableDropping(): void {
    const [dragover$, drop$, dragenter$, dragleave$] = [
      fromEvent(this.elem.nativeElement, 'dragover'),
      fromEvent(this.elem.nativeElement, 'drop'),
      fromEvent(this.elem.nativeElement, 'dragenter'),
      fromEvent(this.elem.nativeElement, 'dragleave')
    ].map(o$ => o$.pipe(filter(() => !this.dropDisabled && this.dragDropService.isDragging())), takeUntilDestroyed());

    /* eslint-disable rxjs-angular/prefer-takeuntil */
    this.zone.runOutsideAngular(() => {
      dragover$.subscribe(event => {
        this.isDraggedOver$.next(true);
        event.preventDefault();
      });
      drop$.subscribe(() => {
        this.coinDrop.emit(this.dragDropService.draggedItem());
        this.isDraggedOver$.next(false);
      });
      dragenter$.subscribe(event => {
        this.isDraggedOver$.next(true);
      });
      dragleave$.subscribe(() => {
        this.isDraggedOver$.next(false);
      });
    });
  }

  private optimizeChangeDetection(): void {
    this.isDraggedOver$.pipe(debounceTime(0), distinctUntilChanged(), takeUntilDestroyed()).subscribe(() => this.zone.run(() => this.cd.markForCheck()));
  }
}
