import { ChangeDetectorRef, Component, DestroyRef, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, ResolveEnd, Router, RouterEvent } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { CoinTab } from '@coin/shared/util-models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * @deprecated Use `coin-v2-tabs` or `coin-v2-routed-tabs` instead.
 */
@Component({
  selector: 'coin-tab-selection',
  templateUrl: './coin-tab-selection.component.html',
  styleUrls: ['./coin-tab-selection.component.scss'],
  standalone: false
})
export class CoinTabSelectionComponent implements OnInit, DoCheck {
  @Input() tabs: CoinTab[];
  @Input() disabled: boolean;
  @Input() withoutRouting: boolean;
  @Input() showSmallScreenDropdown: boolean;
  @Input() paramsHandling: QueryParamsHandling = 'merge';

  @Output() tabChange = new EventEmitter<number>();

  public selectedTab: CoinTab;

  private initialTabs: CoinTab[];
  private updateTabs$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    if (this.withoutRouting) {
      return;
    }
    this.listenToRouteChanges();
    this.listenToTabChanges();
  }

  ngDoCheck(): void {
    if (this.withoutRouting) {
      return;
    }

    const contentChanged = !TinyHelpers.areItemsSimilar(this.tabs, this.initialTabs);

    if (!contentChanged) {
      return;
    }

    this.initialTabs = structuredClone(this.tabs);
    this.updateTabs$.next();
  }

  private listenToTabChanges(): void {
    this.updateTabs$
      .pipe(debounceTime(200))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setSelectedTab(this.router.url));
  }

  private listenToRouteChanges(): void {
    this.router.events
      .pipe(filter(event => event instanceof ResolveEnd))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => this.setSelectedTab((event as RouterEvent).url));
  }

  private setSelectedTab(url: string): void {
    this.selectedTab = this.tabs.find(tab => tab.isSelected) ?? this.tabs.find(tab => url.includes(tab.route) && !tab.isDisabled) ?? this.tabs.find(tab => !tab.isDisabled);
    this.cdr.detectChanges();
  }

  public changeSelection(selectedTab: CoinTab, index?: number): void {
    if (selectedTab.isDisabled) {
      return;
    }

    this.tabChange.emit(index);
    if (!this.withoutRouting) {
      this.router.navigate([selectedTab.route], { queryParamsHandling: this.paramsHandling });
    }
  }

  public displayFn(tab: CoinTab): string {
    return tab.name;
  }
}
