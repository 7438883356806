<mat-form-field
  [matTooltip]="errorTooltip$ | async"
  [matTooltipDisabled]="!touched()"
  matTooltipClass="error-tooltip"
  class="date-input-picker coin-form-field-outline coin-form-field-outline--{{ matFormFieldClass }} no-margin"
  appearance="outline"
  floatLabel="always">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <input
    #input="matInput"
    [style.font-family]="boldValue && value ? 'Siemens Sans Bold !important' : null"
    [placeholder]="placeholder"
    matInput
    [matDatepicker]="datePicker"
    [matDatepickerFilter]="dayFilter"
    [disabled]="disabled"
    [ngModel]="value"
    (ngModelChange)="onInputChange($event)"
    (blur)="onInputBlur(input.value)"
    [min]="min"
    [max]="max" />
  <mat-icon (click)="$event.stopPropagation(); datePicker.open()" aria-label="calendar-button" matSuffix>date_range</mat-icon>

  <mat-datepicker (closed)="onInputBlur(input.value)" #datePicker (opened)="insertTodayButton()"> </mat-datepicker>
</mat-form-field>

<ng-template #todayButton>
  <coin-v2-button type="primary" [text]="'general.today' | translate" (click)="selectToday(datePicker)"> </coin-v2-button>
</ng-template>
