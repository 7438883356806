import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import {
  CommunicationTypeEnum,
  ContentLanguage,
  IncentiveRecordsWorkflowTemplateTypeEnum,
  SeasonTypeEnum,
  TodoStateEnum,
  TodoTaskType,
  TopicOpenType
} from '@coin/shared/util-enums';
import { CommunicationContent, CommunicationContentElements, Todo, Topic } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '@coin/shared/ui-storybook';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-topic-item',
  templateUrl: './topic-item.component.html',
  styleUrls: ['./topic-item.component.scss'],
  standalone: false
})
export class TopicItemComponent implements AfterViewInit, OnDestroy {
  @ViewChild('infoChart') infoChart: ElementRef;

  @Input() item: Topic;
  @Input() carouselItem = false;
  @Input() isModal = false;
  @Input() isShowcase = false;
  @Input() overrideLanguage: ContentLanguage;
  @Input() dateFormats = { de: 'EEE dd.MM.yy', en: 'EEE MM/dd/yy' };
  @Input() isHistoric: boolean;

  @Output() finishTask = new EventEmitter<void>();
  @Output() openClick = new EventEmitter<TopicOpenType>();

  public communicationTypeEnum = CommunicationTypeEnum;
  public seasonTypeEnum = SeasonTypeEnum;
  public todoTaskTypeEnum = TodoTaskType;

  private unsubscribe$ = new Subject<void>();

  get languageContent(): CommunicationContent {
    const foundContent: CommunicationContent = this.item?.contents?.find(
      content => content.language === (this.overrideLanguage ? this.overrideLanguage : this.translateService?.currentLang)
    );
    return foundContent || this.item?.contents?.find(content => content.language === ContentLanguage.ENGLISH);
  }

  get contentDto(): CommunicationContentElements {
    return this.languageContent?.text?.length
      ? JSON.parse(this.languageContent.text)
      : {
          elements: []
        };
  }

  get dateFormat(): string {
    return this.dateFormats[this.translateService?.currentLang?.toLowerCase()];
  }

  get isLetterTask(): boolean {
    return this.item?.todos?.some(todo => !!todo?.letter);
  }

  get isAllocationTask(): boolean {
    return this.item?.taskType === TodoTaskType.Allocation;
  }

  get isFinalIncumbentTask(): boolean {
    return this.item?.taskType === TodoTaskType.FinalIncumbent;
  }

  get isHeadcountTask(): boolean {
    return this.item?.taskType === TodoTaskType.HeadcountActions;
  }

  get isTypeTask(): boolean {
    return (
      this.item.type === CommunicationTypeEnum.Task ||
      this.item.type === IncentiveRecordsWorkflowTemplateTypeEnum.ManagerTask ||
      this.item.type === IncentiveRecordsWorkflowTemplateTypeEnum.EmployeeTask ||
      this.item.type === IncentiveRecordsWorkflowTemplateTypeEnum.ManagerMail ||
      this.item.type === IncentiveRecordsWorkflowTemplateTypeEnum.EmployeeMail
    );
  }

  get allDoneTodos(): Todo[] {
    return this.item?.todos?.filter(todo => todo.state === TodoStateEnum.Done || todo.state === TodoStateEnum.Rejected);
  }

  get topicImage(): string {
    return this.item?.mediaUrl || 'assets/equity/coin-logo-16x9.jpg';
  }

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private destroyRef: DestroyRef
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.createInfoChart();
    });
  }

  private createInfoChart(): void {
    if (!this.infoChart?.nativeElement) {
      return;
    }

    // eslint-disable-next-line no-new
    new Chart(this.infoChart.nativeElement, {
      type: 'pie',
      data: {
        datasets: [
          {
            data: [this.allDoneTodos?.length, this.item?.todos?.length - this.allDoneTodos?.length],
            backgroundColor: ['rgb(108, 103, 124)', 'rgb(215, 228, 238)']
          }
        ]
      },
      options: {
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        hover: {
          mode: null
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: false
      }
    });
  }

  public finishActiveTask(): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'Finish task',
          msg: 'Do you want to set this task to done?',
          cancelMsg: 'Cancel',
          confirmMsg: 'Set to done',
          translate: false
        }
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => result && this.finishTask.emit());
  }

  public openBudgetDistribution(): void {
    this.openClick.emit(TopicOpenType.BudgetDistribution);
  }

  public openContent(): void {
    this.openClick.emit(TopicOpenType.Content);
  }

  public openTafDialog(): void {
    this.openClick.emit(TopicOpenType.TafDialg);
  }

  public openAllocation(): void {
    this.openClick.emit(TopicOpenType.Allocation);
  }

  public openManagerApp(): void {
    this.openClick.emit(TopicOpenType.FinalIncumbent);
  }

  public openHeadcountApproval(): void {
    this.openClick.emit(TopicOpenType.HeadcountActions);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getPercentage(value: number): number {
    if (value !== undefined && !isNaN(value)) {
      return Math.floor(value * 100);
    }
    return 100;
  }
}
