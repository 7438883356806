<div class="input-overview-wrapper">
  @if (displayValue?.personalInfos) {
    <p>{{ 'gbr.overview.step-personal' | translate }}</p>
    <div class="info-grid">
      @if (displayValue.personalInfos.isSingleParent !== null) {
        <coin-simple-gbr-input
          [label]="'gbr.personal-infos.single-parent' | translate"
          [display]="true"
          [ngModel]="displayValue.personalInfos.isSingleParent"
          [type]="simpleInputTypes.YesNo">
        </coin-simple-gbr-input>
      }
      @if (displayValue.personalInfos.isShiftWorker !== null) {
        <coin-simple-gbr-input
          [label]="'gbr.personal-infos.change-shift' | translate"
          [display]="true"
          [ngModel]="displayValue.personalInfos.isShiftWorker"
          [type]="simpleInputTypes.YesNo">
        </coin-simple-gbr-input>
      }
      @if (displayValue.personalInfos.email) {
        <coin-simple-gbr-input [label]="'gbr.personal-infos.email' | translate" [display]="true" [ngModel]="displayValue.personalInfos.email"> </coin-simple-gbr-input>
      }
      @if (displayValue.personalInfos.hasParentLeave !== null) {
        <coin-simple-gbr-input
          [label]="'gbr.personal-infos.parent-leave' | translate"
          [display]="true"
          [ngModel]="displayValue.personalInfos.hasParentLeave"
          [type]="simpleInputTypes.YesNo">
        </coin-simple-gbr-input>
      }
      @if (displayValue.personalInfos.hasDisabilities !== null) {
        <coin-simple-gbr-input
          [label]="'gbr.personal-infos.disability' | translate"
          [display]="true"
          [ngModel]="displayValue.personalInfos.hasDisabilities"
          [type]="simpleInputTypes.YesNo">
        </coin-simple-gbr-input>
      }
      @if (displayValue.personalInfos.hasCare !== null) {
        <coin-simple-gbr-input [label]="'gbr.personal-infos.care' | translate" [display]="true" [ngModel]="displayValue.personalInfos.hasCare" [type]="simpleInputTypes.YesNo">
        </coin-simple-gbr-input>
      }
    </div>
  }

  @if (displayValue?.vacation && stepIndex > 1) {
    <p>{{ 'gbr.overview.step-vacation' | translate }}</p>
    <div class="info-grid">
      @if (displayValue.vacation.country) {
        <coin-simple-gbr-input [label]="'gbr.vacation.country' | translate" [display]="true" [ngModel]="displayValue.vacation.country"> </coin-simple-gbr-input>
      }
      @if (displayValue.vacation?.period?.start) {
        <coin-simple-gbr-input
          [label]="'gbr.vacation.period' | translate"
          [display]="true"
          [ngModel]="displayValue.vacation.period"
          [type]="simpleInputTypes.DateRange"
          class="vacation-period">
        </coin-simple-gbr-input>
      }
    </div>
  }

  @if (displayValue?.spouse && stepIndex > 2) {
    <p>{{ 'gbr.overview.partner-title' | translate }}</p>
    <div class="person">
      <mat-card class="mat-mdc-elevation-specific mat-elevation-z4">
        @if (displayValue.spouse) {
          <coin-siemens-energy-person-view [value]="displayValue.spouse" [display]="true"> </coin-siemens-energy-person-view>
        }
      </mat-card>
    </div>
  }

  @if (displayValue.children?.length && stepIndex > 3) {
    <p>{{ 'gbr.overview.minor-children' | translate }}</p>
    @for (traveler of minorChildren; track traveler) {
      <div class="person">
        <mat-card class="mat-mdc-elevation-specific mat-elevation-z4">
          <coin-siemens-energy-person-view [value]="traveler" [display]="true" [type]="personTypes.Child"> </coin-siemens-energy-person-view>
        </mat-card>
      </div>
    }
    <p>{{ 'gbr.overview.children' | translate }}</p>
    @for (traveler of adultChildren; track traveler) {
      <div class="person">
        <mat-card class="mat-mdc-elevation-specific mat-elevation-z4">
          <coin-siemens-energy-person-view [value]="traveler" [display]="true" [type]="personTypes.Child"> </coin-siemens-energy-person-view>
        </mat-card>
      </div>
    }
  }
</div>
