import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Moment } from 'moment';

export class FormsHelper {
  public static trimValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const trimmedValue = control.value.trim();
        if (trimmedValue !== control.value) {
          // Update the control value to the trimmed value
          control.setValue(trimmedValue);
        }
      }
      return null; // Return null if validation passes
    };
  }

  public static noWhiteSpaceValidator(control: FormControl<string>): ValidationErrors | null {
    if (/\s/.test(control.value)) {
      return { whitespace: 'general.whitespace-validation-error' };
    }
    return null;
  }

  public static dateValidator(dateBoundaries: { minDate?: Moment; maxDate?: Moment }): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null) return null;
      const { minDate, maxDate } = dateBoundaries;

      if (!minDate && !maxDate) throw Error('Either minDate or maxDate is required');

      if (control.value < minDate) {
        return { minDate: 'general.min-date-error' };
      }

      if (control.value > maxDate) {
        return { maxDate: 'general.max-date-error' };
      }

      return null;
    };
  }

  public static prohibitedValueValidator(prohibitedValues: unknown[]): ValidatorFn {
    const valuesAsSet = new Set(prohibitedValues);

    return (control: AbstractControl): ValidationErrors | null => {
      if (valuesAsSet.has(control.value)) {
        return { notAllowed: 'general.value-not-allowed-error' };
      }

      return null;
    };
  }
}
