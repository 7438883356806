import { AbstractControl, ControlValueAccessor, NgControl, TouchedChangeEvent } from '@angular/forms';
import { ChangeDetectorRef, Directive, inject, Injector, OnInit, runInInjectionContext, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Fn } from '@coin/shared/util-models';
import { filter, map, merge, Observable, of, startWith, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { errorsToText } from './error-to-text.helper';

@Directive() // decorator only to enable lifecycle compatibility
export abstract class DefaultControlValueAccessor<T> implements ControlValueAccessor, OnInit {
  protected cd = inject(ChangeDetectorRef);
  protected injector = inject(Injector);
  protected translateService = inject(TranslateService);

  // dependent on control directive
  protected ngControl: NgControl | null;
  public errorTooltip$: Observable<string> = of(null);
  private setDisabled$ = new Subject<void>();
  public touched = signal(false);

  public ngOnInit(): void {
    this.initiateControl();
  }

  // for manual (via ui) value changes
  get value(): T {
    return this._value;
  }
  set value(value: T) {
    this._value = value;
    this.onChange(value);
  }
  protected _value: T;

  protected get isRequired(): boolean {
    const validator = this.ngControl?.control?.validator;
    if (!validator) return false;

    return !!validator({} as AbstractControl)?.required;
  }

  // for programmatic value changes
  writeValue(value: T) {
    this._value = value;
    this.cd.markForCheck();
  }

  onChange: Fn = () => {};
  registerOnChange(fn: Fn) {
    this.onChange = fn;
  }

  onTouch: Fn = () => {};
  registerOnTouched(fn: Fn) {
    this.onTouch = fn;
  }

  public disabled = false;
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.setDisabled$.next();
  }

  private initiateControl() {
    this.ngControl = this.injector.get(NgControl, null);
    const formControl = this.ngControl?.control;

    if (!formControl) return;

    this.errorTooltip$ = merge(this.setDisabled$, this.ngControl.statusChanges).pipe(
      startWith(null),
      map(() => {
        if (!this.ngControl.errors) return null;
        return errorsToText(this.ngControl.errors)
          .map(error => this.translateService.instant(error))
          .join('\n\n');
      })
    );
    runInInjectionContext(this.injector, () => {
      formControl.events
        .pipe(
          filter((event): event is TouchedChangeEvent => event instanceof TouchedChangeEvent),
          map(event => event.touched),
          startWith(formControl.touched),
          takeUntilDestroyed()
        )
        .subscribe(touched => this.touched.set(touched));
    });
  }
}
