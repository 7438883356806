import { AfterViewInit, Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { ListViewFilterTagType } from '@coin/shared/util-enums';
import { ListViewFilterTag, ListViewFilterTagSubFilter, ListViewTagFilterParameter } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { InputComponent } from '../input/input.component';

// TODO refactor whole class
@Component({
  selector: 'coin-c-list-view-filter-tag',
  templateUrl: './list-view-filter-tag.component.html',
  styleUrls: ['./list-view-filter-tag.component.scss'],
  standalone: false
})
export class ListViewFilterTagComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() public tag: ListViewFilterTag;
  @Input() public selections: ListViewTagFilterParameter[];
  @Input() public hideCount: boolean;

  @ViewChildren('textInput') textInputQueryList: QueryList<InputComponent>;

  @Output() filterChanged = new EventEmitter<void>();
  @Output() clearFilter = new EventEmitter<void>();
  @Output() addFilter = new EventEmitter<ListViewTagFilterParameter>();
  @Output() removeFilter = new EventEmitter<ListViewTagFilterParameter>();

  public textInputComponent: InputComponent;
  public isInEditMode = false;
  public inputText: string;
  public selectedFilters: ListViewFilterTagSubFilter[] = [];
  public listViewFilterTagType = ListViewFilterTagType;
  public dateFormControl: FormControl<Moment> = new FormControl<Moment | undefined>(undefined);
  public isBinaryFilterActive = false;

  public get isBinary(): boolean {
    return this.tag.type === ListViewFilterTagType.Binary;
  }

  get showDropdownFilter(): boolean {
    return !this.tag?.type || this.tag?.type === ListViewFilterTagType.DropdownWithoutTotalCount;
  }

  get tagValue(): string {
    if (this.tag) {
      const title = this.tagTitle;

      if (this.isBinary) {
        return `${this.tag.count}` || '';
      }

      if ([ListViewFilterTagType.Input, ListViewFilterTagType.DropdownWithoutTotalCount, ListViewFilterTagType.Date].includes(this.tag.type)) {
        return title;
      }
      if (!this.selectedFilters.length) {
        return `${this.tag.count || ''}`;
      }
      const count = this.selectedFilters
        .map(filter => this.tag.subFilters?.find(subfilter => subfilter.type === filter.type).count)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

      if (Number.isNaN(count) || !this.tag.count) {
        return title;
      }
      return `${title} ${count}/${this.tag.count}`;
    }
    return '';
  }

  private get tagTitle(): string {
    if (!this.selectedFilters.length) {
      return '';
    }
    if (this.selectedFilters.length === 1) {
      return this.translateService.instant(this.selectedFilters[0].title);
    }
    return `Selected: ${this.selectedFilters.length}`;
  }

  constructor(
    private translateService: TranslateService,
    private destroyRef: DestroyRef
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selections) {
      this.setSelectedFiltersFromInput();
    }
  }

  ngAfterViewInit(): void {
    this.textInputQueryList.changes.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(components => {
      this.textInputComponent = components.first;
      setTimeout(() => {
        // If the component just becomes visible focus it
        this.textInputComponent?.focus();
      });
    });
  }

  ngOnInit(): void {
    if (this.selections) {
      this.setSelectedFiltersFromInput();
    } else {
      const selectedItem = this.tag?.subFilters?.find(filter => filter.selected);
      if (selectedItem) {
        this.selectedFilters[0] = selectedItem;
        this.addFilter.emit({ category: this.category, value: selectedItem.type });
      }
    }
    this.dateFormControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.selectDate());
  }

  public isSelected(filter: ListViewFilterTagSubFilter): boolean {
    return this.selectedFilters.findIndex(selected => selected.type === filter.type) > -1;
  }

  public get category(): string {
    return this.tag?.filterKey;
  }

  public onClearTextInput(): void {
    this.inputText = undefined;
  }

  public onFilterClicked(filter: ListViewFilterTagSubFilter): void {
    if (this.isSelected(filter)) {
      this.deselectFilter(filter);
    } else {
      this.selectFilter(filter);
    }
  }

  public selectFilter(filter: ListViewFilterTagSubFilter): void {
    if (this.tag.isMultiSelect) {
      this.selectedFilters.push(filter);
    } else {
      if (this.selectedFilters[0]) {
        this.removeFilter.emit({ category: this.category, value: this.selectedFilters[0].type, intermediate: true });
      }
      this.selectedFilters[0] = filter;
    }
    this.addFilter.emit({ category: this.category, value: filter.type });
  }

  public deselectFilter(filter: ListViewFilterTagSubFilter): void {
    if (filter) {
      this.selectedFilters = this.selectedFilters.filter(selectedFilter => selectedFilter.type !== filter.type);
      this.removeFilter.emit({ category: this.category, value: filter.type, intermediate: false });
    }
  }

  public toggleEditMode(): void {
    if (this.tag.type !== ListViewFilterTagType.Binary) {
      this.isInEditMode = !this.isInEditMode;
    } else if (this.isBinaryFilterActive) {
      this.deselectFilter(this.tag.subFilters[0]);
      this.isBinaryFilterActive = false;
    } else {
      this.selectFilter(this.tag.subFilters[0]);
      this.isBinaryFilterActive = true;
    }
  }

  public reset(): void {
    this.selectedFilters = [];
  }

  public submitTextInput(): void {
    this.isInEditMode = false;
    this.toggleFilterValue(this.inputText);
  }

  public selectDate(): void {
    this.isInEditMode = false;
    this.toggleFilterValue(this.dateFormControl.value?.format('MM/DD/YYYY'));
  }

  private toggleFilterValue(value: string): void {
    if (value) {
      this.selectFilter({
        title: value,
        type: value
      });
    } else if (this.selectedFilters[0]) {
      this.deselectFilter(this.selectedFilters[0]);
    }
  }

  private setSelectedFiltersFromInput(): void {
    this.selectedFilters = [];
    this.isBinaryFilterActive = false;
    this.selections
      ?.filter(selection => this.tag?.subFilters?.length && selection.category === this.category)
      .forEach(selectedSubFilter => {
        const selectedItem = this.tag.subFilters.find(filter => filter.type === selectedSubFilter.value);
        if (selectedItem) {
          const updatedItem: ListViewFilterTagSubFilter = { ...selectedItem, selected: true };
          this.selectedFilters.push(updatedItem);
          if (this.isBinary) {
            this.isBinaryFilterActive = true;
          }
        }
      });
  }
}
