import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicDomAccessDirective, PermissionsDirective } from '@coin/modules/auth/data-management';
import { LoadingModule } from '@coin/shared/data-access';
import { FeatureFlagDirective } from '@coin/shared/data-management-directives';
import { IsoToCountryPipe } from '@coin/shared/data-management-pipes';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { EmployeePictureComponent, V2CountryFlagComponent, V2DialogModule } from '@coin/shared/ui-storybook';
import { EllipsisTooltipDirective } from '@coin/shared/util-directives';
import { EmployeeNamePipe, PureFunctionPipe } from '@coin/shared/util-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { SimplebarAngularModule } from 'simplebar-angular';
import { AuthGmsAngularClientModule } from '../auth-components.module';
import { CoinAppsOverviewComponent } from './coin-apps-overview/coin-apps-overview.component';
import { CoinAppComponent } from './coin-apps-overview/components/coin-app/coin-app.component';
import { ConfirmationAddDeputyDialogComponent } from './confirmation-add-deputy-dialog/confirmation-add-deputy-dialog.component';
import { EmployeePreviewComponent } from './employee-preview/employee-preview.component';
import { FaqAnswerLevelComponent } from './faq-menu/components/faq-answer-level/faq-answer-level.component';
import { FaqInbetweenLevelComponent } from './faq-menu/components/faq-inbetween-level/faq-inbetween-level.component';
import { FaqTopLevelComponent } from './faq-menu/components/faq-top-level/faq-top-level.component';
import { FaqMenuComponent } from './faq-menu/faq-menu.component';
import { LoginScreenComponent } from './login-screen/login-screen.component';
import { LogoutScreenComponent } from './logout-screen/logout-screen.component';
import { ManageDeputiesComponent } from './manage-deputies/manage-deputies.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SelectEmulatePersonComponent } from './select-emulate-person/select-emulate-person.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    LegacyComponentsModule,
    SimplebarAngularModule,
    MatProgressBarModule,
    MatTabsModule,
    LoadingModule,
    MatIconModule,
    MatBadgeModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    InfiniteScrollDirective,
    DynamicDomAccessDirective,
    FeatureFlagDirective,
    PermissionsDirective,
    EmployeeNamePipe,
    IsoToCountryPipe,
    PureFunctionPipe,
    MatIconModule,
    OverlayModule,
    EllipsisTooltipDirective,
    V2DialogModule,
    V2CountryFlagComponent,
    EmployeePictureComponent
  ],
  declarations: [
    SelectEmulatePersonComponent,
    LogoutScreenComponent,
    LoginScreenComponent,
    FaqMenuComponent,
    CoinAppsOverviewComponent,
    FaqAnswerLevelComponent,
    FaqInbetweenLevelComponent,
    FaqTopLevelComponent,
    CoinAppComponent,
    EmployeePreviewComponent,
    ProgressBarComponent,
    UserMenuComponent,
    ManageDeputiesComponent,
    ConfirmationAddDeputyDialogComponent
  ],
  exports: [
    SelectEmulatePersonComponent,
    LogoutScreenComponent,
    LoginScreenComponent,
    FaqMenuComponent,
    CoinAppsOverviewComponent,
    EmployeePreviewComponent,
    ProgressBarComponent,
    UserMenuComponent,
    ManageDeputiesComponent,
    ConfirmationAddDeputyDialogComponent
  ]
})
export class AuthComponentsModule {
  static forTest() {
    return AuthComponentsTestModule;
  }
}

@NgModule({ imports: [AuthComponentsModule, AuthGmsAngularClientModule.forTest()] })
class AuthComponentsTestModule {}
