import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthComponentsModule, AuthGmsAngularClientModule } from '@coin/modules/auth/feature';
import { AuthGmsClientConfig } from '@coin/modules/auth/util';
import { LibGbrModule } from '@coin/modules/gbr/feature';
import { ApiModule as SuccessionManagementApiModule, Configuration as SuccessionManagementApiConfiguration } from '@coin/modules/succession-management/data-access';
import { ApiModule, Configuration as ApiConfiguration } from '@coin/shared/data-access';
import { FeatureFlagDirective } from '@coin/shared/data-management-directives';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { NumberFormatCountryIso } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AppEnvironment, CustomTranslationLoader, TranslationDebugParser } from '@coin/shared/data-access-translation-loader';
import { NgxsModule } from '@ngxs/store';
import { UpdateNotifierComponent } from '@coin/shared/ui-storybook';
import { provideDefaultDateFormats } from '@coin/shared/util-helpers';
import { AdminAppState } from './admin-app-state';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './http-interceptors';
import { ApiModule as MercerMasterDataApiModule, Configuration as MercerMasterDataApiConfiguration } from './master-data/api/generated';
import { SharedModule } from './shared/shared.module';
import { MenuTabsComponent } from './static/header/components/menu-tabs/menu-tabs.component';
import { HeaderComponent } from './static/header/header.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, `${environment.cmsContentProdBucketCloudfront}translations/admin/`, '.json');
}

registerLocaleData(localeDe, NumberFormatCountryIso.German, localeDeExtra);

@NgModule({
  declarations: [AppComponent, HeaderComponent, MenuTabsComponent],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    LegacyComponentsModule,
    AppRoutingModule,
    AuthComponentsModule,
    AuthGmsAngularClientModule.forRoot({ ...environment.authentication, ...environment.adminAuthentication } as AuthGmsClientConfig),
    NgxsModule.forRoot(AdminAppState, {
      developmentMode: !environment.production,
      selectorOptions: { suppressErrors: true }
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    TranslateModule.forRoot(CustomTranslationLoader.createConfig(AppEnvironment.Admin, TranslationDebugParser)),
    MercerMasterDataApiModule.forRoot(() => {
      return new MercerMasterDataApiConfiguration({ basePath: environment.api.baseUrl });
    }),
    SuccessionManagementApiModule.forRoot(() => {
      return new SuccessionManagementApiConfiguration({ basePath: environment.api.baseUrl });
    }),
    ApiModule.forRoot(() => {
      return new ApiConfiguration({ basePath: environment.api.baseUrl });
    }),
    LibGbrModule,
    FeatureFlagDirective,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWithDelay:5000'
    }),
    UpdateNotifierComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: NumberFormatCountryIso.German },
    TranslateModule,
    TranslateService,
    httpInterceptorProviders,
    provideHttpClient(withInterceptorsFromDi()),
    provideDefaultDateFormats()
  ]
})
export class AppModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.loadOperatorIcons();
  }

  private addSvgIcon(name: string): void {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svgs/operators/${name}.svg`));
  }

  private loadOperatorIcons(): void {
    ['contains', 'endswith', 'equal', 'greater', 'greaterorequal', 'less', 'lessorequal', 'notequal', 'dropdocuments', 'notstartswith', 'notcontains'].map(iconName =>
      this.addSvgIcon(iconName)
    );
  }
}
