import { TreeNode } from '../route-builder';

export const SpecialPaymentEquityModule = {
  segment: 'special-payment-equity',
  children: {
    SpecialPaymentEquityComponent: { segment: '' },
    SpecialPaymentEquityIntroductionComponent: { segment: 'introduction' },
    SpecialPaymentEquityMyDashboardComponent: { segment: 'my-dashboard' },
    SpecialPaymentEquityProposalsComponent: { segment: 'proposals', params: ['employeeId'] }
  }
} as const satisfies TreeNode;
