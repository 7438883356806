<button [class.inverted]="iconPos === 'right'" [class.above]="iconPos === 'above'" [disabled]="disabled">
  @if (icon) {
    <mat-icon>{{ icon }}</mat-icon>
  }
  @if (svgIcon) {
    <mat-icon [svgIcon]="svgIcon"></mat-icon>
  }
  <span>{{ text }}</span>
  <ng-content></ng-content>
</button>
