@if (!disabled) {
  <coin-v2-dropdown [(ngModel)]="rule.field"
                    (ngModelChange)="resetOperatorAndValue()"
                    required
                    [attr.aria-label]="'general.rule-engine.field' | translate"
                    [searchDebounce]="300"
                    (search)="scopeSearch$ && scopeSearch$.next($event)">
    @for (option of config | keyvalue; track option) {
      <ng-template [coinValue]="option.key">{{ translateKeys ? ('general.rule-engine.keys.' + option.key | translate) : option.key }}</ng-template>
    }
    @if (!config[rule.field] && rule.field) {
      <ng-template [coinValue]="rule.field">{{ rule.field }}</ng-template>
    }
  </coin-v2-dropdown>
} @else {
  <span>{{ rule.field }}</span>
}
<div [matMenuTriggerFor]="rule.field && ruleConfig?.operators ? operatorOptions : null" [class.disabled]="!rule.field" class="operator">
  @if (rule.operator) {
    <coin-v2-lightweight-operator-icon [operator]="rule.operator"></coin-v2-lightweight-operator-icon>
  }
</div>
<mat-menu #operatorOptions="matMenu">
  @for (operator of ruleConfig?.operators ?? []; track operator) {
    <button [disabled]="operator === rule.operator" (click)="setOperator(operator)" mat-menu-item class="text">
      <div class="menu-option-container">
        <coin-v2-lightweight-operator-icon [operator]="operator"></coin-v2-lightweight-operator-icon>
        <span>{{ operator | enumToText }}</span>
      </div>
    </button>
  }
</mat-menu>

@if (!disabled && !isValuelessOperator(rule.operator) && ruleConfig && !ruleConfig?.isCustom) {
  <div class="rule-engine-input">
    @if (ruleConfig?.type === 'System.String' || ruleConfig?.type === 'System.DateTime' || ruleConfig?.type === 'System.Int32' || ruleConfig?.type === 'System.Double') {
      @if (!freeTextOperators.has(rule.operator) && (ruleConfig.fieldValues$ | async | pure: castToMetadata) | async; as metadata) {
        @if (!metadata.isRawInput) {
          <coin-v2-dropdown
            [(ngModel)]="rule.value"
            [disabled]="isRuleValueDisabled"
            [multiple]="true"
            [searchDebounce]="0"
            (search)="search$.next($event)"
            (ngModelChange)="changes.emit()"
            required
            [attr.aria-label]="'general.rule-engine.value' | translate">
            @if (rule.field) {
              @for (fieldValue of metadata.filteredFieldValues; track fieldValue) {
                <ng-template [coinValue]="fieldValue">
                  {{ fieldValue }}
                </ng-template>
              }
            }
          </coin-v2-dropdown>
        } @else {
          @if (ruleConfig?.type === 'System.String') {
            <coin-v2-text-input
              [(ngModel)]="rule.value[0]"
              [disabled]="isRuleValueDisabled"
              (ngModelChange)="changes.emit()"
              required
              [attr.aria-label]="'general.rule-engine.value' | translate"></coin-v2-text-input>
          }
          @if (ruleConfig?.type === 'System.DateTime') {
            <coin-v2-date-input
              [(ngModel)]="rule.value[0]"
              [disabled]="isRuleValueDisabled"
              (ngModelChange)="changes.emit()"
              required
              [attr.aria-label]="'general.rule-engine.value' | translate"></coin-v2-date-input>
          }
          @if (ruleConfig?.type === 'System.Int32' || ruleConfig?.type === 'System.Double') {
            <coin-v2-number-input
              [(ngModel)]="rule.value[0]"
              [disabled]="isRuleValueDisabled"
              (ngModelChange)="changes.emit()"
              required
              [attr.aria-label]="'general.rule-engine.value' | translate">
            </coin-v2-number-input>
          }
        }
      } @else {
        @if (ruleConfig?.type === 'System.String') {
          <coin-v2-text-input
            [(ngModel)]="rule.value[0]"
            [disabled]="isRuleValueDisabled"
            (ngModelChange)="changes.emit()"
            required
            [attr.aria-label]="'general.rule-engine.value' | translate"></coin-v2-text-input>
        }
        @if (ruleConfig?.type === 'System.DateTime') {
          <coin-v2-date-input
            [(ngModel)]="rule.value[0]"
            [disabled]="isRuleValueDisabled"
            (ngModelChange)="changes.emit()"
            required
            [attr.aria-label]="'general.rule-engine.value' | translate"></coin-v2-date-input>
        }
        @if (ruleConfig?.type === 'System.Int32' || ruleConfig?.type === 'System.Double') {
          <coin-v2-number-input
            [(ngModel)]="rule.value[0]"
            [disabled]="isRuleValueDisabled"
            (ngModelChange)="changes.emit()"
            required
            [attr.aria-label]="'general.rule-engine.value' | translate">
          </coin-v2-number-input>
        }
      }
    }
    @if (ruleConfig?.type === 'System.Boolean') {
      <coin-v2-checkbox
        [(ngModel)]="rule.value[0]"
        [disabled]="isRuleValueDisabled"
        (ngModelChange)="changes.emit()"
        required
        [attr.aria-label]="'general.rule-engine.value' | translate"></coin-v2-checkbox>
    }
  </div>
} @else if (!disabled && !isValuelessOperator(rule.operator) && (ruleConfig?.isCustom || !ruleConfig)) {
  <coin-v2-text-input
    [(ngModel)]="rule.value[0]"
    [disabled]="isRuleValueDisabled"
    (ngModelChange)="changes.emit()"
    required
    [attr.aria-label]="'general.rule-engine.value' | translate"></coin-v2-text-input>
}@else {
  @if (ruleConfig?.type !== 'System.DateTime') {
    <span>{{ rule.value }}</span>
  } @else {
    <span>{{ $any(rule.value) | date }}</span>
  }
}

@if (!disabled) {
  <mat-icon [class.disabled]="!deletable" [matTooltipDisabled]="deletable" [matTooltip]="'general.rule-tooltip' | translate" (click)="onDeleteClick()" class="delete"
    >delete</mat-icon
  >
}
