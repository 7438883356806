/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  OrganisationManagementEmployeesDetailedEmployeeModel,
  OrganisationManagementEmployeesEmployeeModel,
  OrganisationManagementOrganisationsCreateAndUpdateOrganisationsModel,
  OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel,
  OrganisationManagementOrganisationsOrganisationModel,
  OrganisationManagementPositionsPositionModel,
  SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel,
  SharedPageModelOfOrganisationManagementEmployeesEmployeeModel,
  SharedPageModelOfOrganisationManagementOrganisationsOrganisationInfoModel,
  SharedPageModelOfOrganisationManagementPositionsPositionModel,
  SharedPageModelOfOrganisationManagementRolesEmployeeRoleModel,
  SharedRuleEngineRuleSetModel,
  SiemensCOINClientApiHandlersOrganisationManagementActivitiesOrganisationManagementActivityStreamQuery,
  SiemensCOINClientApiRequestsOrganisationManagementEmployeesGetEmployeesByGidsQuery
} from '@coin/shared/util-models';

import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

export interface OrganisationManagementCustomerServiceCreateAndUpdateOrganisationsOrganisationsRequestParams {
  organisationManagementOrganisationsCreateAndUpdateOrganisationsModel?: OrganisationManagementOrganisationsCreateAndUpdateOrganisationsModel;
}

export interface OrganisationManagementCustomerServiceGetByQueryOrganisationsRequestParams {
  validOn?: string;
}

export interface OrganisationManagementCustomerServiceGetByQueryPositionsRequestParams {
  /** The OrganisationId to which the positions records are assigned */
  organisationId?: string;
  /** Filter for given position title */
  title?: string;
  /** The date on which the position records are valid. */
  validOn?: string;
  /** The date on which the position records are valid. */
  validTo?: string;
  isDistinct?: boolean;
  validOnDate?: string;
  validToDate?: string;
}

export interface OrganisationManagementCustomerServiceGetFutureOrganisationsOrganisationsRequestParams {
  id: string;
  validOn?: string;
}

export interface OrganisationManagementCustomerServiceGetOrganisationInformationByQueryOrganisationsRequestParams {
  orgType?: string;
  orgClass?: string;
  orgUnit?: string;
  validOn?: string;
  page?: number;
  size?: number;
}

export interface OrganisationManagementCustomerServiceGetOrganisationsByRuleSetOrganisationsRequestParams {
  sharedRuleEngineRuleSetModel?: SharedRuleEngineRuleSetModel;
}

export interface OrganisationManagementCustomerServiceQueryActivityStreamRequestParams {
  page?: number;
  size?: number;
  siemensCOINClientApiHandlersOrganisationManagementActivitiesOrganisationManagementActivityStreamQuery?: SiemensCOINClientApiHandlersOrganisationManagementActivitiesOrganisationManagementActivityStreamQuery;
}

export interface OrganisationManagementCustomerServiceQueryByGidsEmployeesRequestParams {
  siemensCOINClientApiRequestsOrganisationManagementEmployeesGetEmployeesByGidsQuery?: SiemensCOINClientApiRequestsOrganisationManagementEmployeesGetEmployeesByGidsQuery;
}

export interface OrganisationManagementCustomerServiceQueryDetailsEmployeesRequestParams {
  employeeId: string;
  validOn?: string;
}

export interface OrganisationManagementCustomerServiceQueryEmployeeRoleRequestParams {
  validOn?: string;
  employeeRoleClass?: string;
  roleType?: string;
  page?: number;
  size?: number;
}

export interface OrganisationManagementCustomerServiceQueryEmployeesRequestParams {
  validOn?: string;
  searchString?: string;
  includeInvalidContractStatusAndWorkforceTypes?: boolean;
  page?: number;
  size?: number;
}

export interface OrganisationManagementCustomerServiceSearchPositionsRequestParams {
  search?: string;
  validOn?: string;
  page?: number;
  size?: number;
}

@Injectable({
  providedIn: 'root'
})
export class OrganisationManagementCustomerService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Start process to create and update organisations and all their related information.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createAndUpdateOrganisationsOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceCreateAndUpdateOrganisationsOrganisationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel>;
  public createAndUpdateOrganisationsOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceCreateAndUpdateOrganisationsOrganisationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel>>;
  public createAndUpdateOrganisationsOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceCreateAndUpdateOrganisationsOrganisationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel>>;
  public createAndUpdateOrganisationsOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceCreateAndUpdateOrganisationsOrganisationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const organisationManagementOrganisationsCreateAndUpdateOrganisationsModel = requestParameters?.organisationManagementOrganisationsCreateAndUpdateOrganisationsModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/customer/v1/organisations`;
    return this.httpClient.request<OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementOrganisationsCreateAndUpdateOrganisationsModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get a list of all organizations that are valid at a given date.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getByQueryOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetByQueryOrganisationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<OrganisationManagementOrganisationsOrganisationModel>>;
  public getByQueryOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetByQueryOrganisationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<OrganisationManagementOrganisationsOrganisationModel>>>;
  public getByQueryOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetByQueryOrganisationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<OrganisationManagementOrganisationsOrganisationModel>>>;
  public getByQueryOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetByQueryOrganisationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const validOn = requestParameters?.validOn;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (validOn !== undefined && validOn !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validOn, 'ValidOn');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/customer/v1/organisations`;
    return this.httpClient.request<Array<OrganisationManagementOrganisationsOrganisationModel>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get a list of all Position that are assigned to an given organisation and valid at a given date.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getByQueryPositions(
    requestParameters?: OrganisationManagementCustomerServiceGetByQueryPositionsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<OrganisationManagementPositionsPositionModel>>;
  public getByQueryPositions(
    requestParameters?: OrganisationManagementCustomerServiceGetByQueryPositionsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<OrganisationManagementPositionsPositionModel>>>;
  public getByQueryPositions(
    requestParameters?: OrganisationManagementCustomerServiceGetByQueryPositionsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<OrganisationManagementPositionsPositionModel>>>;
  public getByQueryPositions(
    requestParameters?: OrganisationManagementCustomerServiceGetByQueryPositionsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const organisationId = requestParameters?.organisationId;
    const title = requestParameters?.title;
    const validOn = requestParameters?.validOn;
    const validTo = requestParameters?.validTo;
    const isDistinct = requestParameters?.isDistinct;
    const validOnDate = requestParameters?.validOnDate;
    const validToDate = requestParameters?.validToDate;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (organisationId !== undefined && organisationId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>organisationId, 'OrganisationId');
    }
    if (title !== undefined && title !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>title, 'Title');
    }
    if (validOn !== undefined && validOn !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validOn, 'ValidOn');
    }
    if (validTo !== undefined && validTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validTo, 'ValidTo');
    }
    if (isDistinct !== undefined && isDistinct !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isDistinct, 'IsDistinct');
    }
    if (validOnDate !== undefined && validOnDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validOnDate, 'ValidOnDate');
    }
    if (validToDate !== undefined && validToDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validToDate, 'ValidToDate');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/customer/v1/positions`;
    return this.httpClient.request<Array<OrganisationManagementPositionsPositionModel>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFutureOrganisationsOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetFutureOrganisationsOrganisationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<OrganisationManagementOrganisationsOrganisationModel>>;
  public getFutureOrganisationsOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetFutureOrganisationsOrganisationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<OrganisationManagementOrganisationsOrganisationModel>>>;
  public getFutureOrganisationsOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetFutureOrganisationsOrganisationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<OrganisationManagementOrganisationsOrganisationModel>>>;
  public getFutureOrganisationsOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetFutureOrganisationsOrganisationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getFutureOrganisationsOrganisations.');
    }
    const validOn = requestParameters?.validOn;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (validOn !== undefined && validOn !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validOn, 'ValidOn');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/customer/v1/organisations/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/future`;
    return this.httpClient.request<Array<OrganisationManagementOrganisationsOrganisationModel>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get a page result of organisation infos
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getOrganisationInformationByQueryOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetOrganisationInformationByQueryOrganisationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfOrganisationManagementOrganisationsOrganisationInfoModel>;
  public getOrganisationInformationByQueryOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetOrganisationInformationByQueryOrganisationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfOrganisationManagementOrganisationsOrganisationInfoModel>>;
  public getOrganisationInformationByQueryOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetOrganisationInformationByQueryOrganisationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfOrganisationManagementOrganisationsOrganisationInfoModel>>;
  public getOrganisationInformationByQueryOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetOrganisationInformationByQueryOrganisationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const orgType = requestParameters?.orgType;
    const orgClass = requestParameters?.orgClass;
    const orgUnit = requestParameters?.orgUnit;
    const validOn = requestParameters?.validOn;
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (orgType !== undefined && orgType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgType, 'OrgType');
    }
    if (orgClass !== undefined && orgClass !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgClass, 'OrgClass');
    }
    if (orgUnit !== undefined && orgUnit !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgUnit, 'OrgUnit');
    }
    if (validOn !== undefined && validOn !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validOn, 'ValidOn');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/customer/v1/organisations/organisation-list`;
    return this.httpClient.request<SharedPageModelOfOrganisationManagementOrganisationsOrganisationInfoModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Start process to create and update organisations and all their related information.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getOrganisationsByRuleSetOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetOrganisationsByRuleSetOrganisationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<OrganisationManagementOrganisationsOrganisationModel>>;
  public getOrganisationsByRuleSetOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetOrganisationsByRuleSetOrganisationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<OrganisationManagementOrganisationsOrganisationModel>>>;
  public getOrganisationsByRuleSetOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetOrganisationsByRuleSetOrganisationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<OrganisationManagementOrganisationsOrganisationModel>>>;
  public getOrganisationsByRuleSetOrganisations(
    requestParameters?: OrganisationManagementCustomerServiceGetOrganisationsByRuleSetOrganisationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const sharedRuleEngineRuleSetModel = requestParameters?.sharedRuleEngineRuleSetModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/customer/v1/organisations/rules`;
    return this.httpClient.request<Array<OrganisationManagementOrganisationsOrganisationModel>>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: sharedRuleEngineRuleSetModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Gets the activity stream for organisation management.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryActivityStream(
    requestParameters?: OrganisationManagementCustomerServiceQueryActivityStreamRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel>;
  public queryActivityStream(
    requestParameters?: OrganisationManagementCustomerServiceQueryActivityStreamRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel>>;
  public queryActivityStream(
    requestParameters?: OrganisationManagementCustomerServiceQueryActivityStreamRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel>>;
  public queryActivityStream(
    requestParameters?: OrganisationManagementCustomerServiceQueryActivityStreamRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const siemensCOINClientApiHandlersOrganisationManagementActivitiesOrganisationManagementActivityStreamQuery =
      requestParameters?.siemensCOINClientApiHandlersOrganisationManagementActivitiesOrganisationManagementActivityStreamQuery;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/customer/v1/activity-stream/organisations`;
    return this.httpClient.request<SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: siemensCOINClientApiHandlersOrganisationManagementActivitiesOrganisationManagementActivityStreamQuery,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get a list of all employees that are valid at a given date and contains the search string.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryByGidsEmployees(
    requestParameters?: OrganisationManagementCustomerServiceQueryByGidsEmployeesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<OrganisationManagementEmployeesEmployeeModel>>;
  public queryByGidsEmployees(
    requestParameters?: OrganisationManagementCustomerServiceQueryByGidsEmployeesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<OrganisationManagementEmployeesEmployeeModel>>>;
  public queryByGidsEmployees(
    requestParameters?: OrganisationManagementCustomerServiceQueryByGidsEmployeesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<OrganisationManagementEmployeesEmployeeModel>>>;
  public queryByGidsEmployees(
    requestParameters?: OrganisationManagementCustomerServiceQueryByGidsEmployeesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const siemensCOINClientApiRequestsOrganisationManagementEmployeesGetEmployeesByGidsQuery =
      requestParameters?.siemensCOINClientApiRequestsOrganisationManagementEmployeesGetEmployeesByGidsQuery;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/customer/v1/employees/gids`;
    return this.httpClient.request<Array<OrganisationManagementEmployeesEmployeeModel>>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: siemensCOINClientApiRequestsOrganisationManagementEmployeesGetEmployeesByGidsQuery,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get employee details of given employee id
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryDetailsEmployees(
    requestParameters?: OrganisationManagementCustomerServiceQueryDetailsEmployeesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementEmployeesDetailedEmployeeModel>;
  public queryDetailsEmployees(
    requestParameters?: OrganisationManagementCustomerServiceQueryDetailsEmployeesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementEmployeesDetailedEmployeeModel>>;
  public queryDetailsEmployees(
    requestParameters?: OrganisationManagementCustomerServiceQueryDetailsEmployeesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementEmployeesDetailedEmployeeModel>>;
  public queryDetailsEmployees(
    requestParameters?: OrganisationManagementCustomerServiceQueryDetailsEmployeesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const employeeId = requestParameters?.employeeId;
    if (employeeId === null || employeeId === undefined) {
      throw new Error('Required parameter employeeId was null or undefined when calling queryDetailsEmployees.');
    }
    const validOn = requestParameters?.validOn;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (validOn !== undefined && validOn !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validOn, 'validOn');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/customer/v1/employees/${this.configuration.encodeParam({ name: 'employeeId', value: employeeId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<OrganisationManagementEmployeesDetailedEmployeeModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get a list of all employees that are valid at a given date and contains the search string.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryEmployeeRole(
    requestParameters?: OrganisationManagementCustomerServiceQueryEmployeeRoleRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfOrganisationManagementRolesEmployeeRoleModel>;
  public queryEmployeeRole(
    requestParameters?: OrganisationManagementCustomerServiceQueryEmployeeRoleRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfOrganisationManagementRolesEmployeeRoleModel>>;
  public queryEmployeeRole(
    requestParameters?: OrganisationManagementCustomerServiceQueryEmployeeRoleRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfOrganisationManagementRolesEmployeeRoleModel>>;
  public queryEmployeeRole(
    requestParameters?: OrganisationManagementCustomerServiceQueryEmployeeRoleRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const validOn = requestParameters?.validOn;
    const employeeRoleClass = requestParameters?.employeeRoleClass;
    const roleType = requestParameters?.roleType;
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (validOn !== undefined && validOn !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validOn, 'ValidOn');
    }
    if (employeeRoleClass !== undefined && employeeRoleClass !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>employeeRoleClass, 'EmployeeRoleClass');
    }
    if (roleType !== undefined && roleType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>roleType, 'RoleType');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/customer/v1/employee-role`;
    return this.httpClient.request<SharedPageModelOfOrganisationManagementRolesEmployeeRoleModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get a list of all employees that are valid at a given date and contains the search string.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryEmployees(
    requestParameters?: OrganisationManagementCustomerServiceQueryEmployeesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfOrganisationManagementEmployeesEmployeeModel>;
  public queryEmployees(
    requestParameters?: OrganisationManagementCustomerServiceQueryEmployeesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfOrganisationManagementEmployeesEmployeeModel>>;
  public queryEmployees(
    requestParameters?: OrganisationManagementCustomerServiceQueryEmployeesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfOrganisationManagementEmployeesEmployeeModel>>;
  public queryEmployees(
    requestParameters?: OrganisationManagementCustomerServiceQueryEmployeesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const validOn = requestParameters?.validOn;
    const searchString = requestParameters?.searchString;
    const includeInvalidContractStatusAndWorkforceTypes = requestParameters?.includeInvalidContractStatusAndWorkforceTypes;
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (validOn !== undefined && validOn !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validOn, 'ValidOn');
    }
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>searchString, 'SearchString');
    }
    if (includeInvalidContractStatusAndWorkforceTypes !== undefined && includeInvalidContractStatusAndWorkforceTypes !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>includeInvalidContractStatusAndWorkforceTypes, 'IncludeInvalidContractStatusAndWorkforceTypes');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/customer/v1/employees`;
    return this.httpClient.request<SharedPageModelOfOrganisationManagementEmployeesEmployeeModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get a list of all primary positions that are valid at a given time  and where the employee information (gid, firstname, lastname) contains the search query
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchPositions(
    requestParameters?: OrganisationManagementCustomerServiceSearchPositionsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfOrganisationManagementPositionsPositionModel>;
  public searchPositions(
    requestParameters?: OrganisationManagementCustomerServiceSearchPositionsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfOrganisationManagementPositionsPositionModel>>;
  public searchPositions(
    requestParameters?: OrganisationManagementCustomerServiceSearchPositionsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfOrganisationManagementPositionsPositionModel>>;
  public searchPositions(
    requestParameters?: OrganisationManagementCustomerServiceSearchPositionsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const search = requestParameters?.search;
    const validOn = requestParameters?.validOn;
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (search !== undefined && search !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>search, 'Search');
    }
    if (validOn !== undefined && validOn !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validOn, 'ValidOn');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/customer/v1/positions/employees/search`;
    return this.httpClient.request<SharedPageModelOfOrganisationManagementPositionsPositionModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
}
