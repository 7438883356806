<coin-v2-dialog-frame [header]="header">
  <ng-template #header>
    @if (data.headline) {
      <h4 data-testid="confirm-dialog-headline" class="dialog-title">{{ data.translate ? (data.headline | translate) : data.headline }} {{ getHeadlineData() }}</h4>
    }
  </ng-template>
  <div class="body-container">
    @if (!data.headline) {
      <h4 data-testid="confirm-dialog-msg">{{ data.translate ? getTranslatedValue(data.msg) : data.msg }}</h4>
    }
    @if (data.headline && data.msg) {
      <p class="msg" data-testid="confirm-dialog-msg" [innerHtml]="data.translate ? getTranslatedValue(data.msg) : data.msg"></p>
    }
    @if (data?.dropdownValues?.length) {
      @if (data?.dropdownWithSearch) {
        <coin-v2-dropdown
          required
          style="width: 100%; margin: 10px 0"
          [label]="data?.dropdownLabel"
          #search="dropdownSearch"
          [dataSource]="data.dropdownValues"
          [(ngModel)]="selectedDropdownValue">
          @for (item of search.results(); track item) {
            <ng-template [coinValue]="item">{{ (data?.dropdownDisplayFn || dropdownDisplayFn)(item) }}</ng-template>
          }
        </coin-v2-dropdown>
      } @else {
        <coin-v2-dropdown required style="width: 100%; margin: 10px 0" [label]="data?.dropdownLabel" [(ngModel)]="selectedDropdownValue">
          @for (item of data.dropdownValues; track item) {
            <ng-template [coinValue]="item">{{ (data?.dropdownDisplayFn || dropdownDisplayFn)(item) }}</ng-template>
          }
        </coin-v2-dropdown>
      }
    }
    @if (data.multilineInput) {
      <coin-v2-text-area [rows]="4" class="comment-text" [(ngModel)]="providedInput" placeholder="..." [disabled]="data?.inputReadOnly" />
    }
    @if (data?.provideInput && !data.multilineInput && !data.dateInput) {
      <div class="comment-text">
        <input title="text" name="text" id="text" [type]="data.inputType || 'text'" [(ngModel)]="providedInput" placeholder="..." [readOnly]="data?.inputReadOnly" />
      </div>
    }
    @if (data?.provideInput && !data.multilineInput && data.dateInput) {
      <div class="comment-text">
        <coin-v2-date-input placeholder="..." [(ngModel)]="providedInput"> </coin-v2-date-input>
      </div>
    }

    @if (data?.noteText) {
      <div class="sub-text">
        <mat-icon>warning</mat-icon>
        <p>{{ data.translate ? (data.noteText | translate) : data.noteText }}</p>
      </div>
    }
  </div>

  <coin-v2-dialog-footer color="white" padding="none">
    @if (data.thirdButtonMsg) {
      <coin-v2-button
        [icon]="thirdButtonIcon"
        (click)="onThirdButtonClick()"
        [text]="data.translate ? (data.thirdButtonMsg | translate) : data.thirdButtonMsg"
        type="secondary"
        [disabled]="data?.inputMandatory ? !(data.dateInput ? providedInput : providedInput?.trim()) : false" />
    }
    <div class="v2-footer-spacer"></div>
    @if (data.cancelMsg) {
      <coin-v2-button [icon]="cancelButtonIcon" type="secondary" [text]="data.translate ? (data.cancelMsg | translate) : data.cancelMsg" data-testid="confirm-dialog-cancel" (click)="cancel()" />
    }
    <coin-v2-button
      type="primary"
      [icon]="confirmButtonIcon"
      [text]="data.translate ? (data.confirmMsg | translate) : data.confirmMsg"
      data-testid="confirm-dialog-confirm"
      (click)="confirm()"
      [disabled]="data?.inputMandatory ? !(data.dateInput ? providedInput : providedInput?.trim()) : data.disableConfirmButton">
    </coin-v2-button>
  </coin-v2-dialog-footer>
</coin-v2-dialog-frame>
