<mat-form-field
  [matTooltip]="errorTooltip$ | async"
  [matTooltipDisabled]="!touched()"
  matTooltipClass="error-tooltip"
  class="date-input-picker coin-form-field-outline coin-form-field-outline--{{ matFormFieldClass }} no-margin"
  appearance="outline"
  floatLabel="always">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <input
    #input="matInput"
    [style.font-family]="boldValue && value ? 'Siemens Sans Bold !important' : null"
    [placeholder]="placeholder"
    matInput
    [matDatepicker]="datePicker"
    [disabled]="disabled"
    [ngModel]="value"
    (ngModelChange)="onInputChange($event)"
    (blur)="onInputBlur(input.value)"
    [min]="min"
    [max]="max" />
  <mat-icon (click)="$event.stopPropagation(); datePicker.open()" aria-label="calendar-button" matSuffix>date_range</mat-icon>

  <mat-datepicker (closed)="onInputBlur(input.value)" (monthSelected)="setMonthAndYear($event, datePicker)" startView="year" #datePicker> </mat-datepicker>
</mat-form-field>
