import { PermissionResource } from '@coin/shared/util-enums';
import { TreeNode } from '../route-builder';

export const UserMgmtModule = {
  segment: 'user-mgmt',
  title: 'user-mgmt.title',
  navigationRoot: true,
  emptyPage: true,
  children: {
    UserListComponent: { segment: 'users', title: 'user-mgmt.user-list-headline', icon: 'people_alt', permissions: [PermissionResource.UserManagement] },
    RoleMgmtComponent: { segment: 'roles', title: 'user-mgmt.role-mgmt-headline', icon: 'security', permissions: [PermissionResource.RoleManagement] },
    EndpointGroupsComponent: {
      segment: 'endpoint-groups',
      title: 'user-mgmt.endpoint-groups-headline',
      icon: 'format_list_bulleted',
      permissions: [PermissionResource.EndpointGroups]
    }
  }
} as const satisfies TreeNode;
