/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  ApprovalWorkflowsWorkflowFactoriesCreatePositionRequestWorkflowFactoryModel,
  ApprovalWorkflowsWorkflowFactoriesUpdatePositionRequestWorkflowFactoryModel,
  ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel,
  ApprovalWorkflowsWorkflowFactoryRulesCreatePositionRequestWorkflowFactoryRuleModel,
  ApprovalWorkflowsWorkflowFactoryRulesUpdatePositionRequestWorkflowFactoryRuleModel,
  ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel,
  SharedPageModelOfApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel,
  SharedPageModelOfApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel
} from '@coin/shared/util-models';

import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

export interface ApprovalWorkflowsAdminPositionRequestServiceCreateWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams {
  /** The data to create the workflow-factory with. */
  approvalWorkflowsWorkflowFactoriesCreatePositionRequestWorkflowFactoryModel?: ApprovalWorkflowsWorkflowFactoriesCreatePositionRequestWorkflowFactoryModel;
}

export interface ApprovalWorkflowsAdminPositionRequestServiceCreateWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams {
  /** The unique id of the workflow-factory to create the workflow-factory-rule for. */
  workflowFactoryId: string;
  /** The data to create the workflow-factory-rule with. */
  approvalWorkflowsWorkflowFactoryRulesCreatePositionRequestWorkflowFactoryRuleModel?: ApprovalWorkflowsWorkflowFactoryRulesCreatePositionRequestWorkflowFactoryRuleModel;
}

export interface ApprovalWorkflowsAdminPositionRequestServiceDeleteWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams {
  /** The unique id of the workflow-factory to delete. */
  id: string;
}

export interface ApprovalWorkflowsAdminPositionRequestServiceDeleteWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams {
  /** The unique id of the workflow-factory to delete the workflow-factory-rule of. */
  workflowFactoryId: string;
  /** The unique id of the workflow-factory-rule to delete. */
  id: string;
}

export interface ApprovalWorkflowsAdminPositionRequestServicePageWorkflowFactoriesPositionRequestWorkflowFactoriesRequestParams {
  /** Query all workflow-factories with specific title. */
  titleStartsWith?: string;
  page?: number;
  size?: number;
  orderBy?: 'Asc' | 'Desc';
  property?: string;
}

export interface ApprovalWorkflowsAdminPositionRequestServicePageWorkflowFactoryRulesPositionRequestWorkflowFactoryRulesRequestParams {
  /** The unique id of the workflow-factory to get a page of workflow-factory-rules of. */
  workflowFactoryId: string;
  /** Query all workflow-factory-rules with titles starting with. */
  titleStartsWith?: string;
  page?: number;
  size?: number;
  orderBy?: 'Asc' | 'Desc';
  property?: string;
}

export interface ApprovalWorkflowsAdminPositionRequestServiceUpdateWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams {
  /** The unique id of the workflow-factory to update. */
  id: string;
  /** The data to update the workflow-factory with. */
  approvalWorkflowsWorkflowFactoriesUpdatePositionRequestWorkflowFactoryModel?: ApprovalWorkflowsWorkflowFactoriesUpdatePositionRequestWorkflowFactoryModel;
}

export interface ApprovalWorkflowsAdminPositionRequestServiceUpdateWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams {
  /** The unique id of the workflow-factory to update the workflow-factory-rule of. */
  workflowFactoryId: string;
  /** The unique id of the workflow-factory-rule to update. */
  id: string;
  /** The data to update the workflow-factory-rule with. */
  approvalWorkflowsWorkflowFactoryRulesUpdatePositionRequestWorkflowFactoryRuleModel?: ApprovalWorkflowsWorkflowFactoryRulesUpdatePositionRequestWorkflowFactoryRuleModel;
}

@Injectable({
  providedIn: 'root'
})
export class ApprovalWorkflowsAdminPositionRequestService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Creates a workflow-factory.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createWorkflowFactoryPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceCreateWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>;
  public createWorkflowFactoryPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceCreateWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>>;
  public createWorkflowFactoryPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceCreateWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>>;
  public createWorkflowFactoryPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceCreateWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const approvalWorkflowsWorkflowFactoriesCreatePositionRequestWorkflowFactoryModel =
      requestParameters?.approvalWorkflowsWorkflowFactoriesCreatePositionRequestWorkflowFactoryModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/position-request`;
    return this.httpClient.request<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: approvalWorkflowsWorkflowFactoriesCreatePositionRequestWorkflowFactoryModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Creates a workflow-factory-rule for a workflow-factory.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createWorkflowFactoryRulePositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceCreateWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>;
  public createWorkflowFactoryRulePositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceCreateWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>>;
  public createWorkflowFactoryRulePositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceCreateWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>>;
  public createWorkflowFactoryRulePositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceCreateWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const workflowFactoryId = requestParameters?.workflowFactoryId;
    if (workflowFactoryId === null || workflowFactoryId === undefined) {
      throw new Error('Required parameter workflowFactoryId was null or undefined when calling createWorkflowFactoryRulePositionRequestWorkflowFactoryRules.');
    }
    const approvalWorkflowsWorkflowFactoryRulesCreatePositionRequestWorkflowFactoryRuleModel =
      requestParameters?.approvalWorkflowsWorkflowFactoryRulesCreatePositionRequestWorkflowFactoryRuleModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/${this.configuration.encodeParam({ name: 'workflowFactoryId', value: workflowFactoryId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/position-request/rules`;
    return this.httpClient.request<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: approvalWorkflowsWorkflowFactoryRulesCreatePositionRequestWorkflowFactoryRuleModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Deletes a workflow-factory.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteWorkflowFactoryPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceDeleteWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any>;
  public deleteWorkflowFactoryPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceDeleteWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<any>>;
  public deleteWorkflowFactoryPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceDeleteWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<any>>;
  public deleteWorkflowFactoryPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceDeleteWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteWorkflowFactoryPositionRequestWorkflowFactories.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/position-request/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Deletes a workflow-factory-rule of a workflow-factory.
   * Will update all workflow-factory-rule priorities to account for the now deleted workflow-factory-rule.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteWorkflowFactoryRulePositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceDeleteWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any>;
  public deleteWorkflowFactoryRulePositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceDeleteWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<any>>;
  public deleteWorkflowFactoryRulePositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceDeleteWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<any>>;
  public deleteWorkflowFactoryRulePositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceDeleteWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const workflowFactoryId = requestParameters?.workflowFactoryId;
    if (workflowFactoryId === null || workflowFactoryId === undefined) {
      throw new Error('Required parameter workflowFactoryId was null or undefined when calling deleteWorkflowFactoryRulePositionRequestWorkflowFactoryRules.');
    }
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteWorkflowFactoryRulePositionRequestWorkflowFactoryRules.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/${this.configuration.encodeParam({ name: 'workflowFactoryId', value: workflowFactoryId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/position-request/rules/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Gets a page of workflow-factories.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pageWorkflowFactoriesPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServicePageWorkflowFactoriesPositionRequestWorkflowFactoriesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>;
  public pageWorkflowFactoriesPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServicePageWorkflowFactoriesPositionRequestWorkflowFactoriesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>>;
  public pageWorkflowFactoriesPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServicePageWorkflowFactoriesPositionRequestWorkflowFactoriesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>>;
  public pageWorkflowFactoriesPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServicePageWorkflowFactoriesPositionRequestWorkflowFactoriesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const titleStartsWith = requestParameters?.titleStartsWith;
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const orderBy = requestParameters?.orderBy;
    const property = requestParameters?.property;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (titleStartsWith !== undefined && titleStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>titleStartsWith, 'TitleStartsWith');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }
    if (orderBy !== undefined && orderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orderBy, 'OrderBy');
    }
    if (property !== undefined && property !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>property, 'Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/position-request`;
    return this.httpClient.request<SharedPageModelOfApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Gets a page of workflow-factory-rules of a specific workflowFactoryId.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pageWorkflowFactoryRulesPositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServicePageWorkflowFactoryRulesPositionRequestWorkflowFactoryRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>;
  public pageWorkflowFactoryRulesPositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServicePageWorkflowFactoryRulesPositionRequestWorkflowFactoryRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>>;
  public pageWorkflowFactoryRulesPositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServicePageWorkflowFactoryRulesPositionRequestWorkflowFactoryRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>>;
  public pageWorkflowFactoryRulesPositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServicePageWorkflowFactoryRulesPositionRequestWorkflowFactoryRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const workflowFactoryId = requestParameters?.workflowFactoryId;
    if (workflowFactoryId === null || workflowFactoryId === undefined) {
      throw new Error('Required parameter workflowFactoryId was null or undefined when calling pageWorkflowFactoryRulesPositionRequestWorkflowFactoryRules.');
    }
    const titleStartsWith = requestParameters?.titleStartsWith;
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const orderBy = requestParameters?.orderBy;
    const property = requestParameters?.property;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (titleStartsWith !== undefined && titleStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>titleStartsWith, 'TitleStartsWith');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }
    if (orderBy !== undefined && orderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orderBy, 'OrderBy');
    }
    if (property !== undefined && property !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>property, 'Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/${this.configuration.encodeParam({ name: 'workflowFactoryId', value: workflowFactoryId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/position-request/rules`;
    return this.httpClient.request<SharedPageModelOfApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Updates a workflow-factory.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateWorkflowFactoryPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceUpdateWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>;
  public updateWorkflowFactoryPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceUpdateWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>>;
  public updateWorkflowFactoryPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceUpdateWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>>;
  public updateWorkflowFactoryPositionRequestWorkflowFactories(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceUpdateWorkflowFactoryPositionRequestWorkflowFactoriesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateWorkflowFactoryPositionRequestWorkflowFactories.');
    }
    const approvalWorkflowsWorkflowFactoriesUpdatePositionRequestWorkflowFactoryModel =
      requestParameters?.approvalWorkflowsWorkflowFactoriesUpdatePositionRequestWorkflowFactoryModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/position-request/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<ApprovalWorkflowsWorkflowFactoriesWorkflowFactoryModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: approvalWorkflowsWorkflowFactoriesUpdatePositionRequestWorkflowFactoryModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Updates a workflow-factory-rule of a workflow-factory.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateWorkflowFactoryRulePositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceUpdateWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>;
  public updateWorkflowFactoryRulePositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceUpdateWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>>;
  public updateWorkflowFactoryRulePositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceUpdateWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>>;
  public updateWorkflowFactoryRulePositionRequestWorkflowFactoryRules(
    requestParameters?: ApprovalWorkflowsAdminPositionRequestServiceUpdateWorkflowFactoryRulePositionRequestWorkflowFactoryRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const workflowFactoryId = requestParameters?.workflowFactoryId;
    if (workflowFactoryId === null || workflowFactoryId === undefined) {
      throw new Error('Required parameter workflowFactoryId was null or undefined when calling updateWorkflowFactoryRulePositionRequestWorkflowFactoryRules.');
    }
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateWorkflowFactoryRulePositionRequestWorkflowFactoryRules.');
    }
    const approvalWorkflowsWorkflowFactoryRulesUpdatePositionRequestWorkflowFactoryRuleModel =
      requestParameters?.approvalWorkflowsWorkflowFactoryRulesUpdatePositionRequestWorkflowFactoryRuleModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/admin/approval-workflows/workflow-factories/${this.configuration.encodeParam({ name: 'workflowFactoryId', value: workflowFactoryId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/position-request/rules/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<ApprovalWorkflowsWorkflowFactoryRulesWorkflowFactoryRuleModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: approvalWorkflowsWorkflowFactoryRulesUpdatePositionRequestWorkflowFactoryRuleModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
}
