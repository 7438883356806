import { TreeNode } from '../route-builder';

export const HeadcountOverviewModule = {
  segment: 'headcount-overview',
  children: {
    HeadcountOverviewComponent: { segment: '' },
    HeadcountOverviewIntroductionComponent: { segment: 'introduction' },
    HeadcountOverviewMyTeamComponent: { segment: 'my-team' },
    HeadcountOverviewDepartmentViewComponent: { segment: 'department-view' }
  }
} as const satisfies TreeNode;
