@if (icon || svgIcon || contentLeft) {
  <div class="segment left">
    @if (icon || svgIcon) {
      <mat-icon class="icon" [svgIcon]="svgIcon">{{ icon }}</mat-icon>
    }
    @if (contentLeft) {
      @if (isTemplate(contentLeft)) {
        <ng-container *ngTemplateOutlet="contentLeft"></ng-container>
      } @else {
        <p coinEllipsisTooltip matTooltip>
          {{ contentLeft }}
        </p>
      }
    }
  </div>
}

@if (textRight || textRight === 0) {
  <div class="segment right">
    <div class="text">
      @if (!isTemplate(textRight)) {
        <p coinEllipsisTooltip matTooltip>{{ textRight }}</p>
      } @else {
        <ng-container *ngTemplateOutlet="textRight"></ng-container>
      }
    </div>
  </div>
}
