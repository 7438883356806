@if (errors | pure: errorsToText; as errorMessages) {
  @for (message of errorMessages; track message) {
    <p class="error"><mat-icon>error_outline</mat-icon> {{ message | translate }}</p>
  }
}

@for (message of warnings; track message) {
  <p class="warning"><mat-icon>error_outline</mat-icon> {{ message | translate }}</p>
}

@for (message of infos; track message) {
  <p class="info"><mat-icon>error_outline</mat-icon> {{ message | translate }}</p>
}
