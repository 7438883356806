import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { TemplateWithValueDirective } from '@coin/shared/util-directives';
import { MatIcon } from '@angular/material/icon';
import { ConfirmationDialogConfirm, ConfirmationDialogData } from '@coin/shared/util-models';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { V2DateInputComponent } from '../date-input/v2-date-input.component';
import { V2ButtonComponent } from '../button/v2-button.component';
import { V2DialogModule } from '../dialog-frame/v2-dialog.module';
import { V2DropdownComponent } from '../dropdown/v2-dropdown.component';
import { V2TextAreaComponent } from '../text-area/v2-text-area.component';
import { DropdownSearchDirective } from '../dropdown/dropdown-search.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  imports: [
    TemplateWithValueDirective,
    FormsModule,
    ReactiveFormsModule,
    V2DateInputComponent,
    V2ButtonComponent,
    V2DialogModule,
    V2DropdownComponent,
    TranslateModule,
    MatIcon,
    V2TextAreaComponent,
    DropdownSearchDirective
  ]
})
export class ConfirmationDialogComponent<T = string> implements OnInit {
  providedInput: T;
  selectedDropdownValue: T;
  width: string | number = '400px';
  dropdownValues: {
    visible: boolean;
    value: T;
  }[] = [];

  get cancelButtonIcon(): string {
    return this.data?.cancelBtnIcon || 'close';
  }

  get confirmButtonIcon(): string {
    return this.data?.confirmBtnIcon || 'check';
  }

  get thirdButtonIcon(): string | undefined {
    return this.data?.thirdButtonIcon;
  }

  getTranslatedValue(text: string): string {
    const translatedText = text ? this.translateService.instant(text || '', this.data?.msgInterpolationParams) : '';
    const containsMsgValue = translatedText.includes('{msgValue}');
    return containsMsgValue ? translatedText.replace('{msgValue}', this.data?.msgValue?.toString()) : translatedText;
  }

  getHeadlineData(): string {
    return this.data?.headlineData ? `- ${this.data?.headlineData}` : '';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData<T>,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private destroyRef: DestroyRef,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent<T>, ConfirmationDialogConfirm<T>>
  ) {}

  public static openDialog(dialog: MatDialog, data: ConfirmationDialogData, maxWidth = 'unset', disableClose = true) {
    return dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogConfirm | undefined>(ConfirmationDialogComponent, {
        disableClose,
        maxWidth,
        data
      })
      .beforeClosed();
  }

  public ngOnInit(): void {
    this.dialogRef.addPanelClass?.('d1r-dialog-container');
    this.selectedDropdownValue = this?.data?.currentDropdownValue ?? undefined;
    this.providedInput = this.data?.currentInputValue;
    this.width = this.data.width || this.width;
  }

  dropdownDisplayFn(text: string): string {
    return TinyHelpers.pascalcaseToText(text);
  }

  public cancel(): void {
    if (this.data.confirmCancelIfEdited) {
      this.dialog
        .open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogConfirm | undefined>(ConfirmationDialogComponent, {
          disableClose: true,
          minWidth: '375px',
          data: {
            headline: 'general.confirmation-dialog.unsaved-changes-confirmation-headline',
            translate: true,
            msg: 'general.confirmation-dialog.unsaved-changes-confirmation-msg',
            confirmMsg: 'general.yes-close',
            cancelMsg: 'general.keep-editing'
          }
        })
        .afterClosed()
        .pipe(
          filter(confirmation => !!confirmation),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(() => this.dialogRef.close());
    } else {
      this.dialogRef.close();
    }
  }

  public confirm(): void {
    this.dialogRef.close({
      input: this.providedInput,
      dropdownValue: this.selectedDropdownValue,
      clickedButton: 'Confirm'
    });
  }

  public onThirdButtonClick(): void {
    this.dialogRef.close({
      input: this.providedInput,
      dropdownValue: this.selectedDropdownValue,
      clickedButton: 'ThirdButton'
    });
  }
}
