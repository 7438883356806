import { CommunicationWorkflowType } from '@coin/shared/util-enums';
import { Workflow } from '@coin/shared/util-models';

export class WorkflowHelper {
  public static isEmployeeWorkflow(type: CommunicationWorkflowType): boolean {
    return [CommunicationWorkflowType.EmployeeConfirmation, CommunicationWorkflowType.EmployeeSignature].includes(type);
  }

  public static isManagerWorkflow(type: CommunicationWorkflowType): boolean {
    return [CommunicationWorkflowType.ManagerConfirmationMercerValidation].includes(type);
  }

  public static isManagerWorkflowWithEmployeeNotification(type: CommunicationWorkflowType): boolean {
    return [CommunicationWorkflowType.ManagerConfirmationEmployeeNoActionNeeded].includes(type);
  }

  public static isNoActionWorkflow(type: CommunicationWorkflowType): boolean {
    return type === CommunicationWorkflowType.NoActionNeeded;
  }

  public static getById(id: string, workflows: Workflow[]): Workflow {
    return workflows?.find(w => w.id === id);
  }
}
